import { axiosInstance } from './../constants/constants'

async function create(data) {
    try {
        const response = await axiosInstance.post(`/api/v1/manager/employee`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function read(employeeId) {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/employee/${employeeId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function update(employeeId, data) {
    try {
        const response = await axiosInstance.put(`/api/v1/manager/employee/${employeeId}`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function remove(employeeId) {
    try {
        const response = await axiosInstance.delete(`/api/v1/manager/employee/${employeeId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function list() {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/employee/list`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

export {
    create,
    read,
    update,
    remove,
    list,
}
