import { useEffect, useState } from "react"
import { Flex, Image, Card, Row, Col, Space, Divider, Button, List, message } from 'antd'
import { CloseOutlined, TagOutlined, EditOutlined, PlusOutlined, CheckCircleOutlined, CloseCircleOutlined, ToolOutlined } from '@ant-design/icons'
import * as jobService from '../../../services/job'
import * as imageService from '../../../services/image'
import { useNavigate, useParams, useOutlet, Outlet } from 'react-router-dom'
import 'leaflet/dist/leaflet.css'
import jobIcon from './../../../assets/images/job.png'
import moment from 'moment'
import { JOB_STEP_TYPES } from "../constants"
import { useTranslation } from "react-i18next"

function JobDetailPage() {
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const [job, setJob] = useState()
    const { t } = useTranslation()
    const outlet = useOutlet()

    let {jobId} = useParams()
    jobId = parseInt(jobId)

    async function loadJob() {
        const jobReadResponse = await jobService.read(jobId)
        if (!jobReadResponse.success) {
            messageApi.error(jobReadResponse.message)
            return
        }

        if (!jobReadResponse.data.job) {
            messageApi.error(t('notFound'))
            return
        }

        setJob(jobReadResponse.data.job)
    }

    useEffect(() => {
        loadJob()
        // eslint-disable-next-line
    }, [jobId])

    function onClose() {
        navigate('/jobs', {replace: true})
    }

    return (
        <>
            {contextHolder}
            <Row justify="start" align="top" className="w-100 h-100" gutter={[24, 24]}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Card
                        title={<><img src={jobIcon} alt="" style={{ 'width': 22, 'height': 22 }} /> {t('jobDetail')} #{jobId}</>}
                        loading={!job}
                        extra={
                            <Space size="middle">
                                <Button icon={<EditOutlined />} onClick={() => navigate(`/jobs/update/${jobId}`)}>{t('update')}</Button>
                                <CloseOutlined onClick={onClose} />
                            </Space>
                        }
                    >
                        <Space direction="vertical" size="middle" className="w-100">
                            <Row>
                                <Col style={{ "fontWeight": "bold", "fontSize": "14px" }} xs={24} sm={24} md={6}><TagOutlined style={{ "fontSize": "18px" }} /> &nbsp;{t('jobStatus')}</Col>
                                <Col style={{ "fontSize": "14px" }} xs={24} sm={24} md={18}>
                                    <span style={{ "opacity": "0.3" }}>:</span>&nbsp;
                                    {job?.is_open ? <CheckCircleOutlined style={{ "color": "blue" }} /> : <CloseCircleOutlined style={{ "color": "red" }} />} &nbsp;{job?.is_open ? t('open') : t('closed')}
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ "fontWeight": "bold", "fontSize": "14px" }} xs={24} sm={24} md={6}><TagOutlined style={{ "fontSize": "18px" }} /> &nbsp;{t('holeStatus')}</Col>
                                <Col style={{ "fontSize": "14px" }} xs={24} sm={24} md={18}>
                                    <span style={{ "opacity": "0.3" }}>:</span>&nbsp;
                                    {!job?.is_hole_open ? <CheckCircleOutlined style={{ "color": "blue" }} /> : <CloseCircleOutlined style={{ "color": "red" }} />} &nbsp;{job?.is_hole_open ? t('open') : t('closed')}
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ "fontWeight": "bold", "fontSize": "14px" }} span={6}><TagOutlined style={{ "fontSize": "18px" }} /> &nbsp;{t('jobName')}</Col>
                                <Col style={{ "fontSize": "14px" }} xs={24} sm={24} md={18}><span style={{ "opacity": "0.5" }}>:</span> {job?.name ?? '-'}</Col>
                            </Row>
                            <Row>
                                <Col style={{ "fontWeight": "bold", "fontSize": "14px" }} span={6}><TagOutlined style={{ "fontSize": "18px" }} /> &nbsp;{t('category')}</Col>
                                <Col style={{ "fontSize": "14px" }} xs={24} sm={24} md={18}><span style={{ "opacity": "0.5" }}>:</span> {job?.category?.name ?? '-'}</Col>
                            </Row>
                            <Row>
                                <Col style={{ "fontWeight": "bold", "fontSize": "14px" }} span={6}><TagOutlined style={{ "fontSize": "18px" }} /> &nbsp;{t('startDate')}</Col>
                                <Col style={{ "fontSize": "14px" }} xs={24} sm={24} md={18}><span style={{ "opacity": "0.5" }}>:</span> {moment(job?.start_date).format('YYYY-MM-DD HH:mm')}</Col>
                            </Row>
                            <Row>
                                <Col style={{ "fontWeight": "bold", "fontSize": "14px" }} span={6}><TagOutlined style={{ "fontSize": "18px" }} /> &nbsp;{t('endDate')}</Col>
                                <Col style={{ "fontSize": "14px" }} xs={24} sm={24} md={18}><span style={{ "opacity": "0.5" }}>:</span> {job?.is_open ? '-' : moment(job?.end_date).format('YYYY-MM-DD HH:mm')}</Col>
                            </Row>
                            <Row>
                                <Col style={{ "fontWeight": "bold", "fontSize": "14px" }} span={6}><TagOutlined style={{ "fontSize": "18px" }} /> &nbsp;{t('address')}</Col>
                                <Col style={{ "fontSize": "14px" }} xs={24} sm={24} md={18}><span style={{ "opacity": "0.5" }}>:</span> {job?.address ?? '-'}</Col>
                            </Row>
                            <Row>
                                <Col style={{ "fontWeight": "bold", "fontSize": "14px" }} span={6}><TagOutlined style={{ "fontSize": "18px" }} /> &nbsp;{t('createdDate')}</Col>
                                <Col style={{ "fontSize": "14px" }} xs={24} sm={24} md={18}><span style={{ "opacity": "0.5" }}>:</span> {moment(job?._created_date).format('YYYY-MM-DD HH:mm')}</Col>
                            </Row>
                            <Divider>{t('images')}</Divider>
                            <Row gutter={[12, 12]}>
                                {job?.images?.map((image) => (<Col xs={12} sm={12} md={4} lg={4} xl={4} key={image.md5}>
                                    <Image
                                        className="w-100"
                                        height={140}
                                        preview={{'src': imageService.readUrl(`${image.md5}`)}}
                                        src={imageService.readUrl(`${image.md5}-thumb`)}
                                        style={{'objectFit': 'cover', 'border': '1px solid rgba(0,0,0,0.5)'}}
                                    />
                                </Col>))}
                            </Row>
                            <Divider>{t('note')}</Divider>
                            <div style={{ fontSize: "14px" }}>
                                {job?.note ?? '-'}
                            </div>
                        </Space>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Image.PreviewGroup>
                    {!outlet && <Card
                        title={t('jobSteps')}
                        loading={!job}
                        extra={job?.is_open && <Button size="small" icon={<PlusOutlined />} onClick={() => navigate(`step/new`)}>{t('add')}</Button>}
                        bodyStyle={{ paddingTop: '0px', paddingBottom: 0 }}
                    >
                        <List
                            dataSource={job?.steps ?? []}
                            renderItem={(step) => (
                                <>
                                    <List.Item key={step.job_step_id} style={{ "cursor": "pointer" }} onClick={() => navigate(`step/${step.job_step_id}/update`)}>
                                        <List.Item.Meta
                                            title={<Space>
                                                {!step.end_date ? (
                                                    step.start_date ? <ToolOutlined style={{ "color": "blue" }} /> : <CloseCircleOutlined style={{ "color": "red" }} />
                                                ) : <CheckCircleOutlined style={{ "color": "blue" }} />}
                                                <span>#{step.job_step_id} {JOB_STEP_TYPES[step.type]}</span>
                                            </Space>}
                                            description={<div>{step.team.name}</div>}
                                        />
                                        <div style={{ "display": "flex", "justifyContent": "center", "flexDirection": "column", "color": "gray", "fontSize": "13px" }}>
                                            {step.start_date && <div>
                                                {moment(step.start_date).format('DD/MM HH:mm')}
                                            </div>}
                                            {step.end_date && <div>
                                                {moment(step.end_date).format('DD/MM HH:mm')}
                                            </div>}
                                        </div>
                                    </List.Item>
                                    <Flex wrap="wrap" style={{ paddingBottom: '12px' }} gap="small">
                                        {step?.images?.map((image) => (
                                            <Image
                                                key={image.md5}
                                                height={46}
                                                width={46}
                                                preview={{'src': imageService.readUrl(`${image.md5}`)}}
                                                src={imageService.readUrl(`${image.md5}-thumb`)}
                                                style={{'objectFit': 'cover', 'border': '1px solid rgba(0,0,0,0.5)'}}
                                            />
                                        ))}
                                    </Flex>
                                </>
                            )}
                        />
                    </Card>}
                    </Image.PreviewGroup>
                    {outlet && <Outlet context={{'jobStepCreateEvent': loadJob, 'jobStepUpdateEvent': loadJob}} />}
                </Col>
            </Row>
        </>
    )
}

export default JobDetailPage
