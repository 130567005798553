import { useEffect, useState } from "react"
import { DatePicker, Select, Modal, Space, Card, Form, Input, Button, message } from 'antd'
import { formRules } from './../../../constants/constants'
import { CloseOutlined, UserOutlined } from '@ant-design/icons'
import * as employeeService from './../../../services/employee'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { confirmModal } from "../../../utils/helpers"
import dayjs from 'dayjs'
import { useTranslation } from "react-i18next"

function EmployeeUpdatePage() {
    const navigate = useNavigate()
    const [modal, modalContextHolder] = Modal.useModal()
    const {employeeUpdateEvent} = useOutletContext()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const [employee, setEmployee] = useState()
    const { t } = useTranslation()

    let {employeeId} = useParams()
    employeeId = parseInt(employeeId)

    async function loadEmployee() {
        const employeeReadResponse = await employeeService.read(employeeId)
        if (!employeeReadResponse.success) {
            messageApi.error(employeeReadResponse.message)
            return
        }

        if (!employeeReadResponse.data.employee) {
            messageApi.error(t('notFound'))
            return
        }

        form.setFieldsValue({
            ...employeeReadResponse.data.employee,
            'start_date': employeeReadResponse.data.employee.start_date ? dayjs(employeeReadResponse.data.employee.start_date) : null,
            'end_date': employeeReadResponse.data.employee.end_date ? dayjs(employeeReadResponse.data.employee.end_date) : null,
            'custodies': employeeReadResponse.data.employee.custodies || []
        })
        setEmployee(employeeReadResponse.data.employee)
    }

    useEffect(() => {
        loadEmployee()
        // eslint-disable-next-line
    }, [employeeId])

    async function onFinish(values) {
        setIsSubmit(true)

        if (values.start_date) {
            values.start_date = values.start_date.unix()
        }

        if (values.end_date) {
            values.end_date = values.end_date.unix()
        }

        const employeeUpdateResponse = await employeeService.update(employeeId, values)
        if (!employeeUpdateResponse.success) {
            messageApi.error(employeeUpdateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('employeeUpdatedSuccessMessage'))
        setIsSubmit(false)
        employeeUpdateEvent('update')
    }

    async function removeEmployee() {
        const confirmResult = await confirmModal(modal, {title: t('employeeWillDeleted')})
        if (!confirmResult) {
            return
        }

        setIsSubmit(true)
        const employeeRemoveResponse = await employeeService.remove(employeeId)
        if (!employeeRemoveResponse.success) {
            messageApi.error(employeeRemoveResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('employeeDeletedSuccessMessage'))
        setIsSubmit(false)
        employeeUpdateEvent('remove', {'employee_id': employeeId})
        navigate('/employees', {replace: true})
    }

    function onClose() {
        navigate('/employees', {replace: true})
    }

    return (
        <>
            {contextHolder}
            {modalContextHolder}
            <Card title={<><UserOutlined /> {t('updateEmployee')}</>} loading={!employee} extra={<CloseOutlined onClick={onClose} />}>
                <Form name="employee-update" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                    <Form.Item label={t('nameSurname')} name="name_surname" rules={[formRules.required, formRules.min(3), formRules.max(100)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('startDate')} name="start_date">
                        <DatePicker className="w-100" format="YYYY-MM-DD" allowClear />
                    </Form.Item>
                    <Form.Item label={t('endDate')} name="end_date">
                        <DatePicker className="w-100" format="YYYY-MM-DD" allowClear />
                    </Form.Item>
                    <Form.Item label={t('debit')} name="custodies">
                        <Select mode="tags" maxTagTextLength={100} />
                    </Form.Item>
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Space>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" danger onClick={removeEmployee}>{t('remove')}</Button>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
        </>
    )
}

export default EmployeeUpdatePage
