import React, { createContext, useState } from 'react'

const MeContext = createContext()

export const MeProvider = ({ children }) => {
    const [me, setMeState] = useState(null)

    function setMe(value) {
        setMeState(value)
    }

    return (
        <MeContext.Provider value={{ me, setMe }}>
            {children}
        </MeContext.Provider>
    )
}

export default MeContext
