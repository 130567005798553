import { useEffect, useMemo, useState, useRef } from "react"
import { Row, Col, Switch, Image, DatePicker, Divider, Select, Modal, Space, Card, Form, Input, Button, message } from 'antd'
import { formRules } from './../../../constants/constants'
import { CloseOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import * as jobService from './../../../services/job'
import * as openstreetmapService from './../../../services/openstreetmap'
import * as imageService from '../../../services/image'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { confirmModal, processDataForServerForUpdate } from "../../../utils/helpers"
import dayjs from 'dayjs'
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet"
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import debounce from 'lodash.debounce'
import jobIcon from './../../../assets/images/job.png'
import { useTranslation } from "react-i18next"

const MarkerIconIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
})

function JobUpdatePage() {
    const navigate = useNavigate()
    const [modal, modalContextHolder] = Modal.useModal()
    const {jobUpdateEvent} = useOutletContext()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const [job, setJob] = useState()
    const [categories, setCategories] = useState(null)
    const [coordinate, setCoordinate] = useState()
    const [isOpen, setIsOpen] = useState(true)
    const [ images, setImages ] = useState([])
    const [ isSubmitImage, setIsSubmitImage ] = useState(false)
    const inputImageRef = useRef()
    const { t } = useTranslation()

    let {jobId} = useParams()
    jobId = parseInt(jobId)

    async function loadCategories() {
        setCategories(null)
        const categoryListResponse = await jobService.categoryList()
        if (!categoryListResponse.success) {
            messageApi.error(categoryListResponse.message)
            return
        }

        setCategories(categoryListResponse.data.categories.map(category => ({...category, 'key': category.job_category_id})))
    }

    useEffect(() => {
        loadCategories()
        // eslint-disable-next-line
    }, [])

    async function loadJob() {
        const jobReadResponse = await jobService.read(jobId)
        if (!jobReadResponse.success) {
            messageApi.error(jobReadResponse.message)
            return
        }

        if (!jobReadResponse.data.job) {
            messageApi.error(t('notFound'))
            return
        }

        if (jobReadResponse.data.job.coordinate) {
            setCoordinate([jobReadResponse.data.job.coordinate.x, jobReadResponse.data.job.coordinate.y])
        }

        setIsOpen(jobReadResponse.data.job.is_open)
        setImages(jobReadResponse.data.job.images?.map((image) => image.md5))

        form.setFieldsValue({
            ...jobReadResponse.data.job,
            'start_date': jobReadResponse.data.job.start_date ? dayjs(jobReadResponse.data.job.start_date) : null,
            'end_date': jobReadResponse.data.job.end_date ? dayjs(jobReadResponse.data.job.end_date) : null,
        })
        setJob(jobReadResponse.data.job)
    }

    useEffect(() => {
        loadJob()
        // eslint-disable-next-line
    }, [jobId])

    async function onFinish(values) {
        setIsSubmit(true)
        values = processDataForServerForUpdate(values)

        if (values.start_date) {
            values.start_date = values.start_date.unix()
        }

        if (values.end_date) {
            values.end_date = values.end_date.unix()
        } else {
            values.end_date = null
        }

        values.coordinate = coordinate
        values.images = images

        const jobUpdateResponse = await jobService.update(jobId, values)
        if (!jobUpdateResponse.success) {
            messageApi.error(jobUpdateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('jobUpdatedSuccessMessage'))
        setIsSubmit(false)
        jobUpdateEvent('update')
    }

    async function removeJob() {
        const confirmResult = await confirmModal(modal, {title: t('jobWillDeleted')})
        if (!confirmResult) {
            return
        }

        setIsSubmit(true)
        const jobRemoveResponse = await jobService.remove(jobId)
        if (!jobRemoveResponse.success) {
            messageApi.error(jobRemoveResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('jobDeletedSuccessMessage'))
        jobUpdateEvent('remove', {'job_id': jobId})
        navigate('/jobs', {replace: true})
    }

    function onClose() {
        navigate('/jobs', {replace: true})
    }

    const debounceGetCoordiateAddress = useMemo(() => debounce(async (coordinate) => {
        try {
            const openstreetmapResponse = await openstreetmapService.getCoordinateAddress(coordinate)
            if (openstreetmapResponse.address) {
                form.setFieldsValue({
                    'address': openstreetmapResponse.display_name,
                    'address_country': openstreetmapResponse.address.country,
                    'address_region': openstreetmapResponse.address.region,
                    'address_state': openstreetmapResponse.address.state || openstreetmapResponse.address.province,
                    'address_town': openstreetmapResponse.address.town,
                    'address_village': openstreetmapResponse.address.village
                })
            }
        } catch {}
        // eslint-disable-next-line
    }, 1000), [])

    function MapEvents() {
        useMapEvents({
            click: event => {
                setCoordinate([event.latlng.lat, event.latlng.lng])
                debounceGetCoordiateAddress([event.latlng.lat, event.latlng.lng])
            }
        })
    }

    function onMarkerClick() {
        setCoordinate(null)
        form.setFieldsValue({'address': null, 'address_country': null, 'address_region': null, 'address_state': null, 'address_town': null, 'address_village': null})
    }

    function addImage() {
        inputImageRef.current.click()
    }

    function removeImage(md5) {
        setImages(images => images.filter(imageRecord => imageRecord !== md5))
    }

    async function onImageSelect(event) {
        if (event.target.files.length) {
            setIsSubmitImage(true)
            const formData = new FormData()
            formData.append('image', event.target.files[0])
            const imageCreateResponse = await imageService.create(formData)
            if (!imageCreateResponse.success) {
                messageApi.error(imageCreateResponse.message)
                setIsSubmitImage(false)
                return
            }

            // check exists
            if (images?.includes(imageCreateResponse.data.md5)) {
                return
            }

            setImages(prevImages => [...(prevImages ?? []), imageCreateResponse.data.md5])
            setIsSubmitImage(false)
        }
    }

    return (
        <>
            {contextHolder}
            {modalContextHolder}
            <Card
                title={<><img src={jobIcon} alt="" style={{ 'width': 22, 'height': 22 }} /> {t('updateJob')}</>}
                loading={!job || !categories}
                extra={<Space>
                    <Switch
                        size="large"
                        checkedChildren={t('open')}
                        unCheckedChildren={t('closed')}
                        checked={isOpen}
                        onChange={checked => {
                            if (!checked) {
                                form.setFieldValue('end_date', dayjs())
                            }
                            setIsOpen(checked)
                        }}
                    />
                    <CloseOutlined onClick={onClose} />
                </Space>}
            >
                <Form name="job-update" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                    <Form.Item label={t('name')} name="name" rules={[formRules.min(1), formRules.max(100)]}>
                        <Input maxLength={100} />
                    </Form.Item>
                    <Form.Item label={t('category')}>
                        <Row gutter={[24, 8]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item name="category_id" className="m-0">
                                    <Select options={categories?.map(category => ({'value': category.job_category_id, 'label': category.name}))} allowClear className="m-0" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label={isOpen ? t('startDate') : t('startEndDate')}>
                        <Row gutter={[24, 8]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item name="start_date" className="m-0">
                                    <DatePicker className="w-100" showTime format="YYYY-MM-DD HH:mm" allowClear placeholder={t('startDate')} />
                                </Form.Item>
                            </Col>
                            {!isOpen && <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item name="end_date" initialValue={dayjs()} className="m-0" rules={[formRules.required]}>
                                    <DatePicker className="w-100" showTime format="YYYY-MM-DD HH:mm" allowClear placeholder={t('endDate')} />
                                </Form.Item>
                            </Col>}
                        </Row>
                    </Form.Item>
                    <Form.Item label={t('note')} name="note">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Divider>{t('location')}</Divider>
                    <div style={{width: '100%', height: 300, float: 'left'}}>
                        <MapContainer center={coordinate || [50, 3]} zoom={6} scrollWheelZoom={true} style={{height: '100%'}}>
                            <MapEvents />
                            {coordinate && <Marker icon={MarkerIconIcon} position={coordinate} eventHandlers={{ click: onMarkerClick }} />}
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        </MapContainer>
                    </div>
                    <Row justify="space-between" className="w-100" style={{marginTop: 24, float: 'left'}} gutter={4}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}><Form.Item labelCol={0} wrapperCol={24} name="address" rules={[formRules.min(1), formRules.max(400)]}><Input placeholder={t('address')} /></Form.Item></Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}><Form.Item labelAlign="right" label={t('country')} name="address_country" rules={[formRules.min(1), formRules.max(400)]}><Input /></Form.Item></Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}><Form.Item labelAlign="right" label={t('region')} name="address_region" rules={[formRules.min(1), formRules.max(400)]}><Input /></Form.Item></Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}><Form.Item labelAlign="right" label={t('city')} name="address_state" rules={[formRules.min(1), formRules.max(400)]}><Input /></Form.Item></Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}><Form.Item labelAlign="right" label={t('town')} name="address_town" rules={[formRules.min(1), formRules.max(400)]}><Input /></Form.Item></Col>
                        <Col xs={24} sm={24} md={4} lg={4} xl={4}><Form.Item labelAlign="right" label={t('disctrict')} name="address_village" rules={[formRules.min(1), formRules.max(400)]}><Input /></Form.Item></Col>
                    </Row>
                    <Divider>{t('images')} <PlusCircleOutlined disabled={isSubmitImage} onClick={addImage} /></Divider>
                    <Row gutter={[12, 12]}>
                        {images?.map((imageMd5) => (<Col xs={12} sm={12} md={4} lg={4} xl={4} style={{'position': 'relative'}} key={imageMd5}>
                            <Image
                                className="w-100"
                                height={140}
                                preview={{'src': imageService.readUrl(`${imageMd5}`)}}
                                src={imageService.readUrl(`${imageMd5}-thumb`)}
                                style={{'objectFit': 'cover', 'border': '1px solid rgba(0,0,0,0.5)'}}
                            />
                            <DeleteOutlined onClick={() => removeImage(imageMd5)} size="large" style={{'position': 'absolute', 'right': 10, 'top': 10}} />
                        </Col>))}
                    </Row>
                    <Divider/>
                    <div style={{ marginTop: 24, float: 'left', width: '100%', textAlign: 'right' }}>
                        <Space>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" danger onClick={removeJob}>{t('remove')}</Button>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
            <input ref={inputImageRef} accept="image/*" type='file' id="image-input" className="d-none" onChange={event => onImageSelect(event)} />
        </>
    )
}

export default JobUpdatePage
