import { useState, useRef } from "react"
import { Image, Card, Form, Input, Button, message } from 'antd'
import { formRules } from './../../../constants/constants'
import { DeleteOutlined, CloseOutlined, BankOutlined } from '@ant-design/icons'
import * as companyService from './../../../services/company'
import { useOutletContext, useNavigate } from 'react-router-dom'
import * as imageService from './../../../services/image'
import { useTranslation } from "react-i18next"

function CompanyCreatePage() {
    const navigate = useNavigate()
    const {companyCreateEvent} = useOutletContext()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const [image, setImage] = useState()
    const inputImageRef = useRef()
    const { t } = useTranslation()

    async function onFinish(values) {
        setIsSubmit(true)
        values.logo_md5 = image
        const companyCreateResponse = await companyService.create(values)
        if (!companyCreateResponse.success) {
            messageApi.error(companyCreateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('companyCreatedSuccessMessage'))
        setIsSubmit(false)
        companyCreateEvent('create')
        form.resetFields()
        setImage()
    }

    function onClose() {
        navigate('/companies', {replace: true})
    }

    async function onImageSelect(event) {
        if (event.target.files.length) {
            const formData = new FormData()
            formData.append('image', event.target.files[0])
            const imageCreateResponse = await imageService.create(formData)
            if (!imageCreateResponse.success) {
                messageApi.error(imageCreateResponse.message)
                return
            }
            setImage(imageCreateResponse.data.md5)
        }
    }

    return (
        <>
            {contextHolder}
            <Card title={<><BankOutlined /> {t('newCompany')}</>} extra={<CloseOutlined onClick={onClose} />}>
                <Form name="company-create" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                    <Form.Item label={t('name')} name="name" rules={[formRules.required, formRules.min(1), formRules.max(100)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('logo')}>
                        {image && <div className="w-100" style={{'position': 'relative'}}>
                            <Image className="w-100" height={250} src={imageService.readUrl(image)} style={{'objectFit': 'cover', 'border': '1px solid rgba(0,0,0,0.5)'}} />
                            <DeleteOutlined onClick={() => setImage()} size="large" style={{'position': 'absolute', 'right': 10, 'top': 10}} />
                        </div>}
                        {!image && <Button onClick={() => inputImageRef.current.click()}>{t('choose')}</Button>}
                    </Form.Item>
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                    </div>
                </Form>
            </Card>
            <input ref={inputImageRef} accept="image/*" type='file' id="image-input" className="d-none" onChange={event => onImageSelect(event)} />
        </>
    )
}

export default CompanyCreatePage
