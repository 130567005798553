import React, { useState, useEffect } from 'react'
import { FullscreenExitOutlined, FullscreenOutlined, HomeOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Button, List, Card, Col, Row, Statistic, message } from 'antd'
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import * as jobService from './../../services/job'
import * as statsService from './../../services/stats'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { renderToString } from 'react-dom/server'
import markerIcon from './../../assets/images/marker.png'
import signalingIcon from './../../assets/images/signaling.png'
import jobIcon from './../../assets/images/job.png'
import holeIcon from './../../assets/images/hole.png'
import collectIcon from './../../assets/images/collect.png'
import hardwoodFlooringIcon from './../../assets/images/hardwoodFlooring.png'
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { useTranslation } from 'react-i18next'

const MarkerIconAwaitingJob = (color) => L.divIcon({
    className: "transparent custom-marker-drop-shadow",
    html: renderToString(<img src={markerIcon} alt="" style={{ 'width': 48, 'height': 48 }} />)
})

const MarkerIconExcavation = (color) => L.divIcon({
    className: "transparent custom-marker-drop-shadow",
    html: renderToString(<img src={holeIcon} alt="" style={{ 'width': 48, 'height': 48 }} />)
})

const MarkerIconSignaling = (color) => L.divIcon({
    className: "transparent custom-marker-drop-shadow",
    html: renderToString(<img src={signalingIcon} alt="" style={{ 'width': 48, 'height': 48 }} />)
})

const MarkerIconTechnicalSupport = (color) => L.divIcon({
    className: "transparent custom-marker-drop-shadow",
    html: renderToString(<img src={jobIcon} alt="" style={{ 'width': 48, 'height': 48 }} />)
})

const MarkerIconCollect = (color) => L.divIcon({
    className: "transparent custom-marker-drop-shadow",
    html: renderToString(<img src={collectIcon} alt="" style={{ 'width': 48, 'height': 48 }} />)
})

const MarkerIconHardwoodFlooring = (color) => L.divIcon({
    className: "transparent custom-marker-drop-shadow",
    html: renderToString(<img src={hardwoodFlooringIcon} alt="" style={{ 'width': 48, 'height': 48 }} />)
})

function UpdateMapCentre(props) {
    const map = useMap()

    useEffect(() => {
        if (props.coordinate) {
            map.flyTo(props.coordinate, 14)
        }
        // eslint-disable-next-line
    }, [props.coordinate])
        
    return
}

function MainContent() {
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const [openData, setOpenData] = useState()
    const [activeOpenDataKey, setActiveOpenDataKey] = useState()
    const [panMap, setPanMap] = useState()
    const [stats, setStats] = useState()
    const { t } = useTranslation()
    const fullScreenHandle = useFullScreenHandle()

    async function loadOpenData() {
        setOpenData(null)
        const jobListResponse = await jobService.listOpen()
        if (!jobListResponse.success) {
            messageApi.error(jobListResponse.message)
            return
        }

        setOpenData(jobListResponse.data.jobs
            .map((openJob) => ({...openJob, 'key': openJob.job_id}))
            .map((openJob) => {
                if (openJob?.job_step?.type === 'ExcavationOpen' || openJob?.job_step?.type === 'ExcavationClose') {
                    openJob.job_step.type = 'Excavation'
                }

                return openJob
            }))
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            loadOpenData()
        }, 60000)

        return () => {
            clearInterval(intervalId)
        }
        // eslint-disable-next-line
    }, [activeOpenDataKey])

    async function loadStats() {
        setStats()
        const statsCountsResponse = await statsService.counts()
        if (!statsCountsResponse.success) {
            messageApi.error(statsCountsResponse.message)
            return
        }

        setStats(statsCountsResponse.data.counts)
    }

    useEffect(() => {
        loadStats()
        loadOpenData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (openData && activeOpenDataKey) {
            const activeDataCoordinate = openData.find(openData => openData.key === activeOpenDataKey).coordinate
            if (activeDataCoordinate) {
                setPanMap([activeDataCoordinate.x, activeDataCoordinate.y])
            }
        }
        // eslint-disable-next-line
    }, [activeOpenDataKey])

    return (
        <>
            {contextHolder}
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={t('totalJob')}
                            loading={!!!stats}
                            value={stats?.jobs}
                            valueStyle={{color: '#001529'}}
                            prefix={<img src={jobIcon} alt="" style={{ 'width': 18, 'height': 18 }} />}
                            suffix={t('job')}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={t('totalCompletedJob')}
                            loading={!!!stats}
                            value={stats?.completed_jobs}
                            valueStyle={{color: '#001529'}}
                            prefix={<img src={jobIcon} alt="" style={{ 'width': 18, 'height': 18 }} />}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={t('totalEmployee')}
                            loading={!!!stats}
                            value={stats?.employees}
                            valueStyle={{color: '#001529'}}
                            prefix={<UserOutlined />}
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={t('totalTeam')}
                            loading={!!!stats}
                            value={stats?.teams}
                            valueStyle={{color: '#001529'}}
                            prefix={<TeamOutlined />}
                        />
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FullScreen handle={fullScreenHandle}>
                    <Card bodyStyle={{'padding': 0, 'height': fullScreenHandle.active ? '100vh' : '65vh', 'overflow': 'hidden'}}>
                        <Row className="h-100">
                            <Col xs={18} sm={18} md={18} lg={18} xl={18} className="h-100" style={{'position': 'relative'}}>
                                <Button
                                    icon={fullScreenHandle.active ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                                    style={{'position': 'absolute', 'right': 24, 'top': 24, 'zIndex': 999}}
                                    onClick={() => fullScreenHandle.active ? fullScreenHandle.exit() : fullScreenHandle.enter()}
                                />
                                <MapContainer center={[50, 3]} zoom={6} scrollWheelZoom={true} style={{height: '100%', 'borderTopLeftRadius': '8px', 'borderBottomLeftRadius': '8px'}}>
                                    {openData?.filter(openRecord => openRecord.coordinate).map((openRecord, index) => <Marker
                                        key={index}
                                        title={openRecord.name}
                                        icon={{
                                            'AwaitingJob': MarkerIconAwaitingJob,
                                            'Signaling': MarkerIconSignaling,
                                            'TechnicalSupport': MarkerIconTechnicalSupport,
                                            'Excavation': MarkerIconExcavation,
                                            'Collect': MarkerIconCollect,
                                            'HardwoodFlooring': MarkerIconHardwoodFlooring,
                                        }[openRecord?.job_step?.type ?? 'AwaitingJob'](openRecord?.category?.color)}
                                        position={[openRecord.coordinate.x, openRecord.coordinate.y]}
                                        eventHandlers={{ click: () => {
                                            setActiveOpenDataKey(null)
                                            setTimeout(() => setActiveOpenDataKey(openData.key), 10)
                                        }}}
                                    >
                                    </Marker>)}
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                    <UpdateMapCentre coordinate={panMap} />
                                </MapContainer>
                            </Col>
                            <Col xs={6} sm={6} md={18} lg={6} xl={6}>
                                <List
                                    dataSource={openData || []}
                                    style={{overflowY: 'scroll', 'height': fullScreenHandle.active ? '100vh' : '65vh'}}
                                    renderItem={openData => {
                                        // awaiting job
                                        if (!openData?.job_step) {
                                            return (
                                                <List.Item
                                                    key={openData.key}
                                                    className="prevent-select"
                                                    onClick={() => {
                                                        setActiveOpenDataKey(null)
                                                        setTimeout(() => setActiveOpenDataKey(openData.key), 10)
                                                    }}
                                                    onDoubleClick={() => navigate(`/jobs/detail/${openData.job_id}`)}
                                                    style={{'background': activeOpenDataKey === openData.key ? 'rgba(0, 0, 0, 0.05)' : null, 'borderBottom': '1px solid rgba(0,0,0,0.1)'}}
                                                >
                                                    <Row style={{'padding': '0 12px', 'cursor': 'pointer'}} className="w-100">
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="vertical-center">
                                                            <Row className="w-100 f-bold">
                                                                <Col xs={24} sm={24} md={18} lg={18} xl={18} className="vertical-center">
                                                                    <img src={markerIcon} alt="" style={{ 'width': 24, 'height': 24 }} /> &nbsp;<u>{moment(openData.start_date).format('MM/DD HH:mm') ?? '-'}</u>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className="text-right">İş</Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <UserOutlined /> &nbsp;-
                                                        </Col>
                                                        {openData?.name && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            {openData?.name ?? '-'}
                                                        </Col>}
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{overflow: 'hidden', fontSize: 13}}>
                                                            <HomeOutlined /> &nbsp;<i>{openData.address}</i>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )
                                        }

                                        if (openData.job_step.type === 'TechnicalSupport') {
                                            return (
                                                <List.Item
                                                    key={openData.key}
                                                    className="prevent-select"
                                                    onClick={() => {
                                                        setActiveOpenDataKey(null)
                                                        setTimeout(() => setActiveOpenDataKey(openData.key), 10)
                                                    }}
                                                    onDoubleClick={() => navigate(`/jobs/detail/${openData.job_id}`)}
                                                    style={{
                                                        'background': activeOpenDataKey === openData.key ? 'rgba(0, 0, 0, 0.05)' : null,
                                                        'borderBottom': '1px solid rgba(0,0,0,0.1)',
                                                        'opacity': openData.job_step.is_open ? '1' : '0.6'
                                                    }}
                                                >
                                                    <Row style={{'padding': '0 12px', 'cursor': 'pointer'}} className="w-100">
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="vertical-center">
                                                            <Row className="w-100 f-bold">
                                                                <Col xs={24} sm={24} md={18} lg={18} xl={18} className="vertical-center">
                                                                    <img src={jobIcon} alt="" style={{ 'width': 24, 'height': 24 }} /> &nbsp;
                                                                    <u>{openData.job_step.start_date ? moment(openData.job_step.start_date).format('MM/DD HH:mm') : '-'} / {openData.job_step.end_date ? moment(openData.job_step.end_date).format('MM/DD HH:mm') : '-'}</u>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className="text-right">Teknik Destek</Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <UserOutlined /> &nbsp;{openData?.job_step?.team?.name ?? '-'}
                                                        </Col>
                                                        {openData?.name && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            {openData?.name ?? '-'}
                                                        </Col>}
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{overflow: 'hidden', fontSize: 13}}>
                                                            <HomeOutlined /> &nbsp;<i>{openData.address}</i>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )
                                        }

                                        if (openData.job_step.type === 'Signaling') {
                                            return (
                                                <List.Item
                                                    key={openData.key}
                                                    className="prevent-select"
                                                    onClick={() => {
                                                        setActiveOpenDataKey(null)
                                                        setTimeout(() => setActiveOpenDataKey(openData.key), 10)
                                                    }}
                                                    onDoubleClick={() => navigate(`/jobs/detail/${openData.job_id}`)}
                                                    style={{
                                                        'background': activeOpenDataKey === openData.key ? 'rgba(0, 0, 0, 0.05)' : null,
                                                        'borderBottom': '1px solid rgba(0,0,0,0.1)',
                                                        'opacity': openData.job_step.is_open ? '1' : '0.6'
                                                    }}
                                                >
                                                    <Row style={{'padding': '0 12px', 'cursor': 'pointer'}} className="w-100">
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="vertical-center">
                                                            <Row className="w-100 f-bold">
                                                                <Col xs={24} sm={24} md={18} lg={18} xl={18} className="vertical-center">
                                                                    <img src={signalingIcon} alt="" style={{ 'width': 24, 'height': 24 }} /> &nbsp;
                                                                    <u>{openData.job_step.start_date ? moment(openData.job_step.start_date).format('MM/DD HH:mm') : '-'} / {openData.job_step.end_date ? moment(openData.job_step.end_date).format('MM/DD HH:mm') : '-'}</u>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className="text-right">Sinyalizasyon</Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <UserOutlined /> &nbsp;{openData?.job_step?.team?.name ?? '-'}
                                                        </Col>
                                                        {openData?.name && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            {openData?.name ?? '-'}
                                                        </Col>}
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{overflow: 'hidden', fontSize: 13}}>
                                                            <HomeOutlined /> &nbsp;<i>{openData.address}</i>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )
                                        }

                                        if (openData.job_step.type === 'Excavation') {
                                            return (
                                                <List.Item
                                                    key={openData.key}
                                                    className="prevent-select"
                                                    onClick={() => {
                                                        setActiveOpenDataKey(null)
                                                        setTimeout(() => setActiveOpenDataKey(openData.key), 10)
                                                    }}
                                                    onDoubleClick={() => navigate(`/jobs/detail/${openData.job_id}`)}
                                                    style={{'background': activeOpenDataKey === openData.key ? 'rgba(0, 0, 0, 0.05)' : null, 'borderBottom': '1px solid rgba(0,0,0,0.1)'}}
                                                >
                                                    <Row style={{'padding': '0 12px', 'cursor': 'pointer'}} className="w-100">
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="vertical-center">
                                                            <Row className="w-100 f-bold">
                                                                <Col xs={24} sm={24} md={18} lg={18} xl={18} className="vertical-center">
                                                                    <img src={holeIcon} alt="" style={{ 'width': 24, 'height': 24 }} /> &nbsp;
                                                                    <u>{openData.job_step.start_date ? moment(openData.job_step.start_date).format('MM/DD HH:mm') : '-'} / {openData.job_step.end_date ? moment(openData.job_step.end_date).format('MM/DD HH:mm') : '-'}</u>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className="text-right">
                                                                    Çukur
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <UserOutlined /> &nbsp;{openData?.job_step?.team?.name ?? '-'}
                                                        </Col>
                                                        {openData?.name && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            {openData?.name ?? '-'}
                                                        </Col>}
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{overflow: 'hidden', fontSize: 13}}>
                                                            <HomeOutlined /> &nbsp;<i>{openData.address}</i>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )
                                        }

                                        if (openData.job_step.type === 'Collect') {
                                            return (
                                                <List.Item
                                                    key={openData.key}
                                                    className="prevent-select"
                                                    onClick={() => {
                                                        setActiveOpenDataKey(null)
                                                        setTimeout(() => setActiveOpenDataKey(openData.key), 10)
                                                    }}
                                                    onDoubleClick={() => navigate(`/jobs/detail/${openData.job_id}`)}
                                                    style={{'background': activeOpenDataKey === openData.key ? 'rgba(0, 0, 0, 0.05)' : null, 'borderBottom': '1px solid rgba(0,0,0,0.1)'}}
                                                >
                                                    <Row style={{'padding': '0 12px', 'cursor': 'pointer'}} className="w-100">
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="vertical-center">
                                                            <Row className="w-100 f-bold">
                                                                <Col xs={24} sm={24} md={18} lg={18} xl={18} className="vertical-center">
                                                                    <img src={collectIcon} alt="" style={{ 'width': 24, 'height': 24 }} /> &nbsp;
                                                                    <u>{openData.job_step.start_date ? moment(openData.job_step.start_date).format('MM/DD HH:mm') : '-'} / {openData.job_step.end_date ? moment(openData.job_step.end_date).format('MM/DD HH:mm') : '-'}</u>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className="text-right">
                                                                    Toplama
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <UserOutlined /> &nbsp;{openData?.job_step?.team?.name ?? '-'}
                                                        </Col>
                                                        {openData?.name && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            {openData?.name ?? '-'}
                                                        </Col>}
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{overflow: 'hidden', fontSize: 13}}>
                                                            <HomeOutlined /> &nbsp;<i>{openData.address}</i>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )
                                        }

                                        if (openData.job_step.type === 'HardwoodFlooring') {
                                            return (
                                                <List.Item
                                                    key={openData.key}
                                                    className="prevent-select"
                                                    onClick={() => {
                                                        setActiveOpenDataKey(null)
                                                        setTimeout(() => setActiveOpenDataKey(openData.key), 10)
                                                    }}
                                                    onDoubleClick={() => navigate(`/jobs/detail/${openData.job_id}`)}
                                                    style={{'background': activeOpenDataKey === openData.key ? 'rgba(0, 0, 0, 0.05)' : null, 'borderBottom': '1px solid rgba(0,0,0,0.1)'}}
                                                >
                                                    <Row style={{'padding': '0 12px', 'cursor': 'pointer'}} className="w-100">
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="vertical-center">
                                                            <Row className="w-100 f-bold">
                                                                <Col xs={24} sm={24} md={18} lg={18} xl={18} className="vertical-center">
                                                                    <img src={hardwoodFlooringIcon} alt="" style={{ 'width': 24, 'height': 24 }} /> &nbsp;
                                                                    <u>{openData.job_step.start_date ? moment(openData.job_step.start_date).format('MM/DD HH:mm') : '-'} / {openData.job_step.end_date ? moment(openData.job_step.end_date).format('MM/DD HH:mm') : '-'}</u>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={6} lg={6} xl={6} className="text-right">
                                                                    Parke Döşeme
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <UserOutlined /> &nbsp;{openData?.job_step?.team?.name ?? '-'}
                                                        </Col>
                                                        {openData?.name && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            {openData?.name ?? '-'}
                                                        </Col>}
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{overflow: 'hidden', fontSize: 13}}>
                                                            <HomeOutlined /> &nbsp;<i>{openData.address}</i>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card>
                    </FullScreen>
                </Col>
            </Row>
        </>
    )
}

export default MainContent