import * as imageService from './../../../services/image'

export const PRODUCTS_TABLE_COLUMNS = (t) => {
    return [
        {title: '#', dataIndex: 'product_id', key: 'product_id', render: productId => `#${productId}`},
        {title: t('image'), dataIndex: 'image_md5', key: 'image_md5', render: imageMd5 => imageMd5 ? (
            <div className="vertical-center"><img src={imageService.readUrl(imageMd5 + '-thumb')} alt={imageMd5} style={{'width': 36, 'height': 36, 'objectFit': 'cover', 'borderRadius': 5, 'border': '1px solid black'}} /></div>
        ) : '-'},
        {title: t('name'), dataIndex: 'name', key: 'name'},
        {title: t('stockCode'), dataIndex: 'stock_code', key: 'stock_code', render: stockCode => stockCode ?? '-'},
        {title: t('unit'), dataIndex: 'unit', key: 'unit'},
    ]
}