import axios from "axios"

const formRules = {
    'required': {'required': true, 'message': 'Bu alan boş bırakılamaz!'},
    'email': {type: 'email', message: 'Geçerli bir e-posta adresi giriniz!'},
    'min': (min) => ({min, message: `Bu alan en az ${min} karakter olmalıdır!`}),
    'max': (max) => ({max, message: `Bu alan en fazla ${max} karakter olmalıdır!`}),
    'minNumber': (min) => ({min, message: `En az ${min} olmalıdır!`}),
}

const teamServicesTurkish = {
    'Signaling': 'Sinyalizasyon',
    'TechnicalSupport': 'Teknik Destek',
    'Excavation': 'Çukur',
    'Warehouse': 'Depo',
    'Collect': 'Toplama',
    'HardwoodFlooring': 'Parke Döşeme'
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': 'application/json'}
})

export {
    formRules,
    axiosInstance,
    teamServicesTurkish
}
