import { axiosInstance } from './../constants/constants'

async function counts() {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/stats/counts`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

export {
    counts,
}
