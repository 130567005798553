import { ExclamationCircleOutlined } from '@ant-design/icons'

// object içindeki undefined ve boş stringleri temizler
function removeObjectUndefinedOrEmptyString(data) {
    const dataProcessed = {}
    Object.keys(data).forEach(key => {
        if (typeof data[key] !== 'undefined' && data[key] !== "") {
            dataProcessed[key] = data[key]
        }
    })
    return dataProcessed
}

// object içindeki undefined ve boş stringleri null yapar
function nullConvertObjectUndefinedOrEmptyString(data) {
    const dataProcessed = {}
    Object.keys(data).forEach(key => {
        if (typeof data[key] === 'undefined' || data[key] === "") {
            dataProcessed[key] = null
        } else {
            dataProcessed[key] = data[key]
        }
    })
    return dataProcessed
}

// object içindeki string verileri trimler
function objectTrimString(data) {
    const dataProcessed = {}
    Object.keys(data).forEach(key => {
        if (typeof data[key] === 'string') {
            dataProcessed[key] = data[key].trim()
        } else {
            dataProcessed[key] = data[key]
        }
    })
    return dataProcessed
}

export const processDataForServer = data => removeObjectUndefinedOrEmptyString(objectTrimString(data))
export const processDataForServerForUpdate = data => nullConvertObjectUndefinedOrEmptyString(objectTrimString(data))

export const delay = time => new Promise(resolve => setTimeout(resolve, time))

export const confirmModal = (modal, ops = {}) => {
    return new Promise(resolve => {
        modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Devam etmek istediğinize emin misiniz?',
            okText: 'Sil',
            cancelText: 'Vazgeç',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
            ...ops
        })
    })
}
