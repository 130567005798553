import { axiosInstance } from './../constants/constants'

async function create(data) {
    try {
        const response = await axiosInstance.post(`/api/v1/manager/image`, data, {
            'headers': {'x-token': localStorage.getItem('xToken'), "Content-Type": "multipart/form-data"}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

function readUrl(md5) {
    const xToken = localStorage.getItem('xToken')
    return `${process.env.REACT_APP_API_URL}/api/v1/manager/image/${md5}?xToken=${xToken}`
}

export {
    create,
    readUrl,
}
