import { useEffect, useState } from "react"
import { Col, Row, Card, Button, Table, message } from 'antd'
import { BankOutlined, PlusCircleOutlined } from '@ant-design/icons'
import * as companyService from './../../../services/company'
import { COMPANIES_TABLE_COLUMNS } from './constants'
import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function CompaniesPage() {
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const [companies, setCompanies] = useState()
    const { t } = useTranslation()

    async function loadCompanies() {
        setCompanies(null)
        const companyListResponse = await companyService.list()
        if (!companyListResponse.success) {
            messageApi.error(companyListResponse.message)
            return
        }

        setCompanies(companyListResponse.data.companies.map(company => ({...company, 'key': company.company_id})))
    }

    useEffect(() => {
        loadCompanies()
        // eslint-disable-next-line
    }, [])

    function onNewcompanyButtonClick() {
        navigate('/companies/new')
    }

    function oncompanyCreateEvents(event) {
        if (event === 'create') {
            loadCompanies()
        }
    }

    function oncompanyUpdateEvents(event, eventData) {
        if (event === 'update') {
            loadCompanies()
        } else if (event === 'remove') {
            setCompanies(lastState => lastState.filter(company => company.company_id !== eventData.company_id))
        }
    }

    function onRowClick() {
        navigate(`/companies/update/${this.company_id}`)
    }

    return (
        <>
            {contextHolder}
            <Row justify="start" align="top" className="w-100 h-100" gutter={24}>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Card
                        title={<><BankOutlined /> {t('companies')}</>} bodyStyle={{padding: 0}}
                        extra={<Button icon={<PlusCircleOutlined />} onClick={onNewcompanyButtonClick} loading={!companies}>{t('addNew')}</Button>}
                    >
                        <Table size="small" dataSource={companies} columns={COMPANIES_TABLE_COLUMNS(t)} loading={!companies} onRow={record => ({onClick: onRowClick.bind(record)})}/>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Outlet context={{'companyCreateEvent': oncompanyCreateEvents, 'companyUpdateEvent': oncompanyUpdateEvents}} />
                </Col>
            </Row>
        </>
    )
}

export default CompaniesPage
