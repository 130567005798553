import { axiosInstance } from './../constants/constants'

// job
async function create(data) {
    try {
        const response = await axiosInstance.post(`/api/v1/manager/job`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function read(jobId) {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/job/${jobId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function update(jobId, data) {
    try {
        const response = await axiosInstance.put(`/api/v1/manager/job/${jobId}`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function remove(jobId) {
    try {
        const response = await axiosInstance.delete(`/api/v1/manager/job/${jobId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function list(data) {
    try {
        const response = await axiosInstance.post(`/api/v1/manager/job/list`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function listOpen() {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/job/list/open`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function listAwaitingAction() {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/job/list/awaiting-action`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

// step
async function stepCreate(jobId, data) {
    try {
        const response = await axiosInstance.post(`/api/v1/manager/job/${jobId}/step`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function stepRead(jobId, jobStepId) {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/job/${jobId}/step/${jobStepId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function stepUpdate(jobId, jobStepId, data) {
    try {
        const response = await axiosInstance.put(`/api/v1/manager/job/${jobId}/step/${jobStepId}`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function stepRemove(jobId, jobStepId) {
    try {
        const response = await axiosInstance.delete(`/api/v1/manager/job/${jobId}/step/${jobStepId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

// category
async function categoryCreate(data) {
    try {
        const response = await axiosInstance.post(`/api/v1/manager/job/category`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function categoryRead(categoryId) {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/job/category/${categoryId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function categoryUpdate(categoryId, data) {
    try {
        const response = await axiosInstance.put(`/api/v1/manager/job/category/${categoryId}`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function categoryRemove(categoryId) {
    try {
        const response = await axiosInstance.delete(`/api/v1/manager/job/category/${categoryId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function categoryList() {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/job/category/list`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

export {
    create,
    read,
    update,
    remove,
    list,
    listOpen,
    listAwaitingAction,

    stepCreate,
    stepRead,
    stepUpdate,
    stepRemove,

    categoryCreate,
    categoryRead,
    categoryUpdate,
    categoryRemove,
    categoryList
}
