import moment from 'moment'

export const EMPLOYEES_TABLE_COLUMNS = (t) => {
    return [
        {title: '#', dataIndex: 'employee_id', key: 'employee_id', render: employeeId => `#${employeeId}`},
        {title: t('nameSurname'), dataIndex: 'name_surname', key: 'name_surname'},
        {title: t('startEnd'), dataIndex: 'start_end_date', key: 'start_end_date', render: (_, record) => {
            return (record.start_date ? moment(record.start_date).format('YYYY-MM-DD') : '-') + ' / ' + (record.end_date ? moment(record.end_date).format('YYYY-MM-DD') : '-')
        }},
    ]
}