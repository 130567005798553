import { TEAM_SERVICES } from '../constants'
import * as imageService from './../../../services/image'

export const TEAMS_TABLE_COLUMNS = (t) => {
    return [
        {title: '#', dataIndex: 'team_id', key: 'team_id', render: teamId => `#${teamId}`},
        {title: t('company'), dataIndex: 'company', key: 'company', render: (_, record) => record.company ? (
            record.company.logo_md5 ? (
                <div className="vertical-center"><img src={imageService.readUrl(record.company.logo_md5 + '-thumb')} alt={record.company.name} style={{'width': 36, 'height': 36, 'objectFit': 'cover', 'borderRadius': 5, 'border': '1px solid black'}} /></div>
            ) : record.company.name
        ) : '-'},
        {title: t('name'), dataIndex: 'name', key: 'name'},
        {title: t('service'), dataIndex: 'service', key: 'service', render: service => TEAM_SERVICES[service]},
    ]
}