import { useEffect, useState, useRef } from "react"
import { Image, Modal, Space, Card, Form, Input, Button, message } from 'antd'
import { formRules } from './../../../constants/constants'
import { DeleteOutlined, CloseOutlined, BankOutlined } from '@ant-design/icons'
import * as companyService from './../../../services/company'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { confirmModal } from "../../../utils/helpers"
import * as imageService from './../../../services/image'
import { useTranslation } from "react-i18next"

function CompanyUpdatePage() {
    const navigate = useNavigate()
    const [modal, modalContextHolder] = Modal.useModal()
    const {companyUpdateEvent} = useOutletContext()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const [company, setCompany] = useState()
    const [image, setImage] = useState(null)
    const { t } = useTranslation()
    const inputImageRef = useRef()

    let {companyId} = useParams()
    companyId = parseInt(companyId)

    async function loadCompany() {
        const companyReadResponse = await companyService.read(companyId)
        if (!companyReadResponse.success) {
            messageApi.error(companyReadResponse.message)
            return
        }

        if (!companyReadResponse.data.company) {
            messageApi.error(t('notFound'))
            return
        }

        form.setFieldsValue(companyReadResponse.data.company)
        setCompany(companyReadResponse.data.company)
        setImage(companyReadResponse.data.company.logo_md5)
    }

    useEffect(() => {
        loadCompany()
        // eslint-disable-next-line
    }, [companyId])

    async function onFinish(values) {
        setIsSubmit(true)
        values.logo_md5 = image || null
        const companyUpdateResponse = await companyService.update(companyId, values)
        if (!companyUpdateResponse.success) {
            messageApi.error(companyUpdateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('companyUpdatedSuccessMessage'))
        setIsSubmit(false)
        companyUpdateEvent('update')
    }

    async function removeCompany() {
        const confirmResult = await confirmModal(modal, {title: t('companyWillDeleted')})
        if (!confirmResult) {
            return
        }

        setIsSubmit(true)
        const companyRemoveResponse = await companyService.remove(companyId)
        if (!companyRemoveResponse.success) {
            messageApi.error(companyRemoveResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('companyDeletedSuccessMessage'))
        setIsSubmit(false)
        companyUpdateEvent('remove', {'company_id': companyId})
        navigate('/companies', {replace: true})
    }

    function onClose() {
        navigate('/companies', {replace: true})
    }

    async function onImageSelect(event) {
        if (event.target.files.length) {
            const formData = new FormData()
            formData.append('image', event.target.files[0])
            const imageCreateResponse = await imageService.create(formData)
            if (!imageCreateResponse.success) {
                messageApi.error(imageCreateResponse.message)
                return
            }
            setImage(imageCreateResponse.data.md5)
        }
    }

    return (
        <>
            {contextHolder}
            {modalContextHolder}
            <Card title={<><BankOutlined /> {t('updateCompany')}</>} loading={!company} extra={<CloseOutlined onClick={onClose} />}>
                <Form name="company-update" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                    <Form.Item label={t('name')} name="name" rules={[formRules.required, formRules.min(1), formRules.max(100)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('logo')}>
                        {image && <div className="w-100" style={{'position': 'relative'}}>
                            <Image className="w-100" height={250} src={imageService.readUrl(image)} style={{'objectFit': 'cover', 'border': '1px solid rgba(0,0,0,0.5)'}} />
                            <DeleteOutlined onClick={() => setImage()} size="large" style={{'position': 'absolute', 'right': 10, 'top': 10}} />
                        </div>}
                        {!image && <Button onClick={() => inputImageRef.current.click()}>{t('choose')}</Button>}
                    </Form.Item>
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Space>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" danger onClick={removeCompany}>{t('remove')}</Button>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
            <input ref={inputImageRef} accept="image/*" type='file' id="image-input" className="d-none" onChange={event => onImageSelect(event)} />
        </>
    )
}

export default CompanyUpdatePage
