import { axiosInstance } from './../constants/constants'

async function login(eMail, password) {
    try {
        const response = await axiosInstance.post(
            `/api/v1/manager/auth/login`, 
            {'e_mail': eMail, 'password': password}
        )
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

export {
    login,
}
