import { TeamOutlined, PieChartOutlined, UnorderedListOutlined, UserOutlined, BankOutlined } from '@ant-design/icons'
import jobIcon from './../../assets/images/job.png'

export const MENU_ITEMS = (t, awaitingActionJobsCount) => [
    {'key': 'main', 'icon': <PieChartOutlined />, 'label': t('starting')},
    {
        'key': 'products-base',
        'icon': <UnorderedListOutlined />,
        'label': t('products'),
        'children': [
            { 'key': 'products', 'label': t('productList') }
        ]
    },
    {'key': 'companies', 'icon': <BankOutlined />, 'label': t('companies')},
    {'key': 'employees', 'icon': <UserOutlined />, 'label': t('employees')},
    {'key': 'teams', 'icon': <TeamOutlined />, 'label': t('teams')},
    {
        'key': 'jobs-base',
        'icon': <img src={jobIcon} alt="" style={{ 'width': 24, 'height': 24 }} />,
        'label': t('jobs'),
        'children': [
            { 'key': 'jobs', 'label': t('jobs'), },
            { 'key': 'jobs-awaiting-action', 'label': `${t('awaitingAction')} (${awaitingActionJobsCount})`, },
            { 'key': 'job-categories', 'label': t('categories'), }
        ]
    }
]
