import { useEffect, useState } from "react"
import { Select, Modal, Space, Card, Form, Input, Button, message } from 'antd'
import { formRules } from './../../../constants/constants'
import { CloseOutlined, TeamOutlined } from '@ant-design/icons'
import * as teamService from './../../../services/team'
import * as employeeService from './../../../services/employee'
import * as companyService from './../../../services/company'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { confirmModal, processDataForServerForUpdate } from "../../../utils/helpers"
import { TEAM_SERVICES_OPTIONS } from './../constants'
import { useTranslation } from "react-i18next"

function TeamUpdatePage() {
    const navigate = useNavigate()
    const [modal, modalContextHolder] = Modal.useModal()
    const {teamUpdateEvent} = useOutletContext()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const [team, setTeam] = useState()
    const [employees, setEmployees] = useState()
    const [companies, setCompanies] = useState()
    const { t } = useTranslation()

    let {teamId} = useParams()
    teamId = parseInt(teamId)

    async function loadEmployees() {
        setEmployees(null)
        const employeeListResponse = await employeeService.list()
        if (!employeeListResponse.success) {
            messageApi.error(employeeListResponse.message)
            return
        }

        setEmployees(employeeListResponse.data.employees?.map(employee => ({...employee, 'key': employee.employee_id})))
    }

    async function loadCompanies() {
        setCompanies(null)
        const companiesListResponse = await companyService.list()
        if (!companiesListResponse.success) {
            messageApi.error(companiesListResponse.message)
            return
        }

        setCompanies(companiesListResponse.data.companies.map(company => ({...company, 'key': company.company_id})))
    }

    useEffect(() => {
        loadEmployees()
        loadCompanies()
        // eslint-disable-next-line
    }, [])

    async function loadTeam() {
        const teamReadResponse = await teamService.read(teamId)
        if (!teamReadResponse.success) {
            messageApi.error(teamReadResponse.message)
            return
        }

        if (!teamReadResponse.data.team) {
            messageApi.error(t('notFound'))
            return
        }

        form.setFieldsValue({
            ...teamReadResponse.data.team,
            'custodies': teamReadResponse.data.team.custodies || [],
            'employee_ids': teamReadResponse.data.team.employees?.map(employee => employee.employee_id),
        })
        setTeam(teamReadResponse.data.team)
    }

    useEffect(() => {
        loadTeam()
        // eslint-disable-next-line
    }, [teamId])

    async function onFinish(values) {
        setIsSubmit(true)
        values = processDataForServerForUpdate(values)
        const teamUpdateResponse = await teamService.update(teamId, values)
        if (!teamUpdateResponse.success) {
            messageApi.error(teamUpdateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('teamUpdatedSuccessMessage'))
        setIsSubmit(false)
        teamUpdateEvent('update')
    }

    async function removeTeam() {
        const confirmResult = await confirmModal(modal, {title: t('teamWillDeleted')})
        if (!confirmResult) {
            return
        }

        setIsSubmit(true)
        const teamRemoveResponse = await teamService.remove(teamId)
        if (!teamRemoveResponse.success) {
            messageApi.error(teamRemoveResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('teamDeletedSuccessMessage'))
        setIsSubmit(false)
        teamUpdateEvent('remove', {'team_id': teamId})
        navigate('/teams', {replace: true})
    }

    function onClose() {
        navigate('/teams', {replace: true})
    }

    return (
        <>
            {contextHolder}
            {modalContextHolder}
            <Card title={<><TeamOutlined /> {t('updateTeam')}</>} loading={!team || !employees || !companies} extra={<CloseOutlined onClick={onClose} />}>
                <Form name="team-update" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                    <Form.Item label={t('name')} name="name" rules={[formRules.required, formRules.min(3), formRules.max(100)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('company')} name="company_id">
                        <Select options={companies?.map(company => ({ 'value': company.company_id, 'label': company.name }))} allowClear />
                    </Form.Item>
                    <Form.Item label={t('service')} name="service" rules={[formRules.required]}>
                        <Select options={TEAM_SERVICES_OPTIONS}/>
                    </Form.Item>
                    <Form.Item label={t('debit')} name="custodies">
                        <Select mode="tags" maxTagTextLength={100} />
                    </Form.Item>
                    <Form.Item label={t('loginCode')} name="login_code" rules={[formRules.required, formRules.min(6), formRules.max(20)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('employees')} name="employee_ids">
                        <Select mode="tags" options={employees?.map(employee => ({'value': employee.employee_id, 'label': employee.name_surname}))} />
                    </Form.Item>
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Space>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" danger onClick={removeTeam}>{t('remove')}</Button>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
        </>
    )
}

export default TeamUpdatePage
