import { axiosInstance } from './../constants/constants'

async function create(data) {
    try {
        const response = await axiosInstance.post(`/api/v1/manager/team`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function read(teamId) {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/team/${teamId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function update(teamId, data) {
    try {
        const response = await axiosInstance.put(`/api/v1/manager/team/${teamId}`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function remove(teamId) {
    try {
        const response = await axiosInstance.delete(`/api/v1/manager/team/${teamId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function list() {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/team/list`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

export {
    create,
    read,
    update,
    remove,
    list,
}
