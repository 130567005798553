import { useEffect, useState } from "react"
import { Modal, Space, Card, Form, Input, Button, ColorPicker, message } from 'antd'
import { formRules } from './../../../../constants/constants'
import { CloseOutlined, TagOutlined } from '@ant-design/icons'
import * as jobService from './../../../../services/job'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { confirmModal } from "./../../../../utils/helpers"
import { useTranslation } from "react-i18next"

function CategoryUpdatePage() {
    const navigate = useNavigate()
    const [modal, modalContextHolder] = Modal.useModal()
    const {categoryUpdateEvent} = useOutletContext()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const [category, setCategory] = useState()
    const { t } = useTranslation()

    let {jobCategoryId} = useParams()
    jobCategoryId = parseInt(jobCategoryId)

    async function loadCategory() {
        const categoryReadResponse = await jobService.categoryRead(jobCategoryId)
        if (!categoryReadResponse.success) {
            messageApi.error(categoryReadResponse.message)
            return
        }

        if (!categoryReadResponse.data.category) {
            messageApi.error(t('notFound'))
            return
        }

        form.setFieldsValue(categoryReadResponse.data.category)
        setCategory(categoryReadResponse.data.category)
    }

    useEffect(() => {
        loadCategory()
        // eslint-disable-next-line
    }, [jobCategoryId])

    async function onFinish(values) {
        setIsSubmit(true)
        values.color = typeof values.color === 'string' ? values.color : `#${values.color.toHex()}`
        const categoryUpdateResponse = await jobService.categoryUpdate(jobCategoryId, values)
        if (!categoryUpdateResponse.success) {
            messageApi.error(categoryUpdateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('categoryUpdatedSuccessMessage'))
        setIsSubmit(false)
        categoryUpdateEvent('update')
    }

    async function removeCategory() {
        const confirmResult = await confirmModal(modal, {title: t('categoryWillDeleted')})
        if (!confirmResult) {
            return
        }

        setIsSubmit(true)
        const categoryRemoveResponse = await jobService.categoryRemove(jobCategoryId)
        if (!categoryRemoveResponse.success) {
            messageApi.error(categoryRemoveResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('categoryDeletedSuccessMessage'))
        setIsSubmit(false)
        categoryUpdateEvent('remove', {'job_category_id': jobCategoryId})
        navigate('/job-categories', {replace: true})
    }

    function onClose() {
        navigate('/job-categories', {replace: true})
    }

    return (
        <>
            {contextHolder}
            {modalContextHolder}
            <Card title={<><TagOutlined /> {t('updateCategory')}</>} loading={!category} extra={<CloseOutlined onClick={onClose} />}>
                <Form name="product-update" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                    <Form.Item label={t('name')} name="name" rules={[formRules.required, formRules.min(1), formRules.max(100)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('color')} name="color" rules={[formRules.required]}>
                        <ColorPicker disabledAlpha format="hex" />
                    </Form.Item>
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Space>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" danger onClick={removeCategory}>{t('remove')}</Button>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
        </>
    )
}

export default CategoryUpdatePage
