import { useEffect, useRef, useState } from "react"
import { Image, InputNumber, DatePicker, Row, Col, Card, Form, Input, Select, Button, Space, Modal, Empty, message } from 'antd'
import { formRules } from '../../../constants/constants'
import { CloseOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import * as jobService from '../../../services/job'
import * as teamService from '../../../services/team'
import * as productService from '../../../services/product'
import * as imageService from '../../../services/image'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { JOB_STEP_TYPE_OPTIONS } from "../constants"
import { confirmModal } from "../../../utils/helpers"
import dayjs from 'dayjs'
import { useTranslation } from "react-i18next"

let productIdSeq = 0

function JobStepUpdatePage() {
    const navigate = useNavigate()
    const { jobStepUpdateEvent } = useOutletContext()
    const [ form ] = Form.useForm()
    const [ modal, modalContextHolder ] = Modal.useModal()
    const [ messageApi, contextHolder ] = message.useMessage()
    const [ isSubmit, setIsSubmit ] = useState(false)
    const [ teams, setTeams ] = useState()
    const [ jobStepProducts, setJobStepProducts ] = useState([])
    const [ products, setProducts ] = useState({})
    const [ images, setImages ] = useState([])
    const [ isSubmitImage, setIsSubmitImage ] = useState(false)
    const { t } = useTranslation()
    const inputImageRef = useRef()

    let { jobId, jobStepId } = useParams()

    async function onFinish(values) {
        setIsSubmit(true)

        if (values.start_date) {
            values.start_date = values.start_date.unix()
        }

        if (values.end_date) {
            values.end_date = values.end_date.unix()
        }

        values.products = jobStepProducts.filter(jobStepProduct => jobStepProduct).map(jobStepProduct => ({...jobStepProduct, 'job_step_id': undefined, 'product_id_seq': undefined}))
        values.images = images

        const jobStepUpdateResponse = await jobService.stepUpdate(jobId, jobStepId, values)
        if (!jobStepUpdateResponse.success) {
            messageApi.error(jobStepUpdateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('jobStepUpdatedSuccessMessage'))
        setIsSubmit(false)
        jobStepUpdateEvent()
    }

    async function removeJobStep() {
        const confirmResult = await confirmModal(modal, {title: t('jobStepWillDeleted')})
        if (!confirmResult) {
            return
        }

        setIsSubmit(true)
        const jobStepRemoveResponse = await jobService.stepRemove(jobId, jobStepId)
        if (!jobStepRemoveResponse.success) {
            messageApi.error(jobStepRemoveResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('jobStepDeletedSuccessMessage'))
        jobStepUpdateEvent()
        navigate(`/jobs/detail/${jobId}`, {replace: true})
    }

    async function loadJobStep() {
        const jobStepReadResponse = await jobService.stepRead(jobId, jobStepId)
        if (!jobStepReadResponse.success) {
            messageApi.error(jobStepReadResponse.message)
            return
        }

        if (!jobStepReadResponse.data.job_step) {
            messageApi.error(t('notFound'))
            return
        }

        form.setFieldsValue({
            ...jobStepReadResponse.data.job_step,
            'start_date': jobStepReadResponse.data.job_step.start_date ? dayjs(jobStepReadResponse.data.job_step.start_date) : null,
            'end_date': jobStepReadResponse.data.job_step.end_date ? dayjs(jobStepReadResponse.data.job_step.end_date) : null,
            'products': undefined
        })

        setImages(jobStepReadResponse.data.job_step.images?.map((image) => image.md5))

        setJobStepProducts(jobStepReadResponse.data.job_step.products?.map(product => ({
            ...product,
            'product_id_seq': ++productIdSeq,
            '_created_date': undefined,
        })) || [])
    }

    async function loadProducts() {
        setProducts(null)
        const productListResponse = await productService.list()
        if (!productListResponse.success) {
            messageApi.error(productListResponse.message)
            return
        }

        const productsMap = {}
        for (const product of productListResponse.data.products) {
            productsMap[product.product_id] = product
            product.key = product.product_id
        }

        setProducts(productsMap)
    }

    async function loadTeams() {
        setTeams(null)
        const teamListResponse = await teamService.list()
        if (!teamListResponse.success) {
            messageApi.error(teamListResponse.message)
            return
        }

        setTeams(teamListResponse.data.teams.map(team => ({...team, 'key': team.team_id})))
    }

    useEffect(() => {
        loadJobStep()
        loadTeams()
        loadProducts()
        // eslint-disable-next-line
    }, [])

    function onClose() {
        navigate(`/jobs/detail/${jobId}`, {replace: true})
    }

    function addProduct() {
        setJobStepProducts(jobStepProducts => [
            {
                'product_id_seq': ++productIdSeq,
                'product_id': null,
                'quantity': 0
            },
            ...jobStepProducts
        ])
    }

    function removeProduct(productIdSeq) {
        setJobStepProducts(jobStepProducts => jobStepProducts.filter(jobStepProduct => jobStepProduct.product_id_seq !== productIdSeq))
    }

    function updateProduct(updatedProduct) {
        setJobStepProducts(jobStepProducts => jobStepProducts.map(jobStepProduct => jobStepProduct.product_id_seq === updatedProduct.product_id_seq ? updatedProduct : jobStepProduct))
    }

    function addImage() {
        inputImageRef.current.click()
    }

    function removeImage(md5) {
        setImages(images => images.filter(imageRecord => imageRecord !== md5))
    }

    async function onImageSelect(event) {
        if (event.target.files.length) {
            setIsSubmitImage(true)
            const formData = new FormData()
            formData.append('image', event.target.files[0])
            const imageCreateResponse = await imageService.create(formData)
            if (!imageCreateResponse.success) {
                messageApi.error(imageCreateResponse.message)
                setIsSubmitImage(false)
                return
            }

            // check exists
            if (images?.includes(imageCreateResponse.data.md5)) {
                return
            }

            setImages(prevImages => [...(prevImages ?? []), imageCreateResponse.data.md5])
            setIsSubmitImage(false)
        }
    }

    return (
        <>
            {contextHolder}
            {modalContextHolder}
            <Form name="job-step-update" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                <Space direction="vertical" className="w-100">
                    <Card
                        loading={!teams}
                        title={<span>{t('updateJobStep')} #{jobStepId}</span>}
                        extra={<CloseOutlined onClick={onClose} />}
                    >
                        <Form.Item label={t('team')} name="team_id" rules={[formRules.required]}>
                            <Select options={teams?.map(team => ({'value': team.team_id, 'label': team.name}))} className="m-0" />
                        </Form.Item>
                        <Form.Item label={t('jobType')} name="type" rules={[formRules.required]}>
                            <Select options={JOB_STEP_TYPE_OPTIONS} />
                        </Form.Item>
                        <Form.Item label={t('startDate')}>
                            <Form.Item name="start_date" className="m-0">
                                <DatePicker className="w-100" showTime format="YYYY-MM-DD HH:mm" allowClear placeholder={t('startDate')} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label={t('endDate')}>
                            <Form.Item name="end_date" className="m-0">
                                <DatePicker className="w-100" showTime format="YYYY-MM-DD HH:mm" allowClear placeholder={t('endDate')} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label={t('note')} name="note">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                        <Form.Item label={t('teamNote')} name="team_note">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Card>
                    <Card loading={!teams} title={t('usedProducts')} extra={<PlusCircleOutlined onClick={addProduct} />}>
                        <Space className="w-100" direction="vertical">
                            {jobStepProducts.length === 0 && <Empty />}
                            {jobStepProducts.map(jobStepProduct => <Row className="w-100" gutter={[4, 4]} key={jobStepProduct.product_id_seq}>
                                <Col xs={24} sm={24} md={16} lg={14} xl={14} className="w-100">
                                    <Form.Item
                                        initialValue={jobStepProduct.product_id}
                                        wrapperCol={24}
                                        rules={[formRules.required]}
                                        name={['products', jobStepProduct.product_id_seq, 'product_id']}
                                        className="m-0 w-100"
                                    >
                                        <Select
                                            options={Object.values(products ?? {}).map(product => ({'value': product.product_id, 'label': product.name}))}
                                            placeholder={t('product')}
                                            className="w-100"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => `${option.label}`.toLowerCase().includes(input.toLocaleLowerCase())}
                                            onChange={productId => updateProduct({...jobStepProduct, 'product_id': productId})}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item
                                        initialValue={jobStepProduct.quantity}
                                        wrapperCol={24}
                                        rules={[formRules.required]}
                                        name={['products', jobStepProduct.product_id_seq, 'quantity']}
                                        className="m-0 w-100"
                                    >
                                        <InputNumber
                                            min={0.01}
                                            addonAfter={products && jobStepProduct.product_id ? products[jobStepProduct.product_id].unit : ''}
                                            className="w-100"
                                            onChange={quantity => updateProduct({...jobStepProduct, 'quantity': quantity})}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={4} sm={4} md={12} lg={2} xl={2} style={{'textAlign': 'center'}}>
                                    <Button icon={<DeleteOutlined />} onClick={() => removeProduct(jobStepProduct.product_id_seq)} />
                                </Col>
                            </Row>)}
                        </Space>
                    </Card>
                    <Card loading={!teams} title={t('images')} extra={<PlusCircleOutlined disabled={isSubmitImage} onClick={addImage} />}>
                        {!images?.length && <Empty />}
                        <Row gutter={[12, 12]}>
                            {images?.map((imageMd5) => (<Col xs={12} sm={12} md={4} lg={4} xl={4} style={{'position': 'relative'}} key={imageMd5}>
                                <Image
                                    className="w-100"
                                    height={140}
                                    preview={{'src': imageService.readUrl(`${imageMd5}`)}}
                                    src={imageService.readUrl(`${imageMd5}-thumb`)}
                                    style={{'objectFit': 'cover', 'border': '1px solid rgba(0,0,0,0.5)'}}
                                />
                                <DeleteOutlined onClick={() => removeImage(imageMd5)} size="large" style={{'position': 'absolute', 'right': 10, 'top': 10}} />
                            </Col>))}
                        </Row>
                    </Card>
                    <Card loading={!teams}>
                        <div style={{ float: 'left', width: '100%', textAlign: 'right' }}>
                            <Space>
                                <Button loading={isSubmit} disabled={isSubmit} type="primary" danger onClick={removeJobStep}>{t('remove')}</Button>
                                <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                            </Space>
                        </div>
                    </Card>
                </Space>
            </Form>
            <input ref={inputImageRef} accept="image/*" type='file' id="image-input" className="d-none" onChange={event => onImageSelect(event)} />
        </>
    )
}

export default JobStepUpdatePage
