import { useEffect, useState } from "react"
import { Card, Form, Input, Select, Button, message } from 'antd'
import { formRules } from '../../../constants/constants'
import { CloseOutlined } from '@ant-design/icons'
import * as jobService from '../../../services/job'
import * as teamService from '../../../services/team'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { JOB_STEP_TYPE_OPTIONS } from "./../constants"
import { useTranslation } from "react-i18next"

function JobStepCreatePage() {
    const navigate = useNavigate()
    const {jobStepCreateEvent} = useOutletContext()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const { jobId } = useParams()
    const [ teams, setTeams ] = useState()
    const { t } = useTranslation()

    async function onFinish(values) {
        setIsSubmit(true)
        const jobStepCreateResponse = await jobService.stepCreate(jobId, values)
        if (!jobStepCreateResponse.success) {
            messageApi.error(jobStepCreateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('jobStepCreatedSuccessMessage'))
        setIsSubmit(false)
        jobStepCreateEvent()
        form.resetFields()
    }

    async function loadTeams() {
        setTeams(null)
        const teamListResponse = await teamService.list()
        if (!teamListResponse.success) {
            messageApi.error(teamListResponse.message)
            return
        }

        setTeams(teamListResponse.data.teams.map(team => ({...team, 'key': team.team_id})))
    }

    useEffect(() => {
        loadTeams()
        // eslint-disable-next-line
    }, [])

    function onClose() {
        navigate(`/jobs/detail/${jobId}`, {replace: true})
    }

    return (
        <>
            {contextHolder}
            <Card loading={!teams} title={t('newJobStep')} extra={<CloseOutlined onClick={onClose} />}>
                <Form name="job-step-create" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                    <Form.Item label={t('team')} name="team_id" rules={[formRules.required]}>
                        <Select options={teams?.map(team => ({'value': team.team_id, 'label': team.name}))} className="m-0" />
                    </Form.Item>
                    <Form.Item label={t('jobType')} name="type" rules={[formRules.required]}>
                        <Select options={JOB_STEP_TYPE_OPTIONS} />
                    </Form.Item>
                    <Form.Item label={t('note')} name="note">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                    </div>
                </Form>
            </Card>
        </>
    )
}

export default JobStepCreatePage
