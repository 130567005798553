import React, { useState, useEffect, useContext, useRef } from 'react'
import { Col, Row, Spin, Menu, Layout, Avatar, Popover, Button, Space, Select, message } from 'antd'
import { useNavigate, useOutlet } from 'react-router-dom'
import MeContext from '../../context/meContext'
import * as meService from './../../services/me'
import * as jobService from './../../services/job'
import { UserOutlined, LogoutOutlined, DownloadOutlined } from '@ant-design/icons'
import { delay } from './../../utils/helpers'
import { MENU_ITEMS } from './constants'
import MainContent from './mainContent'
import { useTranslation } from 'react-i18next'
const { Header, Content, Sider, Footer } = Layout

function MainPage() {
    const navigate = useNavigate()
    const {me, setMe} = useContext(MeContext)
    const [messageApi, contextHolder] = message.useMessage()

    useEffect(() => {
        async function checkToken() {
            if (localStorage.getItem('xToken') === null) {
                navigate('/login')
                return
            }

            const meReadResponse = await meService.read()
            if (!meReadResponse.success) {
                messageApi.error(meReadResponse.message)
                localStorage.removeItem('xToken')
                navigate('/login')
                return
            }

            setMe(meReadResponse.data.me)
        }

        checkToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {contextHolder}
            {!me && <Row className="h-100 w-100" justify="center" align="middle"><Col><Spin size="large" /></Col></Row>}
            {me && <MainLayout />}
        </>
    )
}

function MainLayout() {
    const outlet = useOutlet()
    const navigate = useNavigate()
    const [ collapsed, setCollapsed ] = useState(false)
    const { setMe } = useContext(MeContext)
    const [ messageApi, contextHolder ] = message.useMessage()
    const [ awaitingActionJobsCount, setAwaitingActionJobsCount ] = useState(0)
    const downloadRef = useRef(null)
    const { t } = useTranslation()

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const listAwaitingActionRes = await jobService.listAwaitingAction()
                setAwaitingActionJobsCount(listAwaitingActionRes.data.jobs.length)
            } catch (err) {}
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    async function userActions(action) {
        if (action === 'logout') {
            localStorage.removeItem('xToken')
            messageApi.success(t('logoutSuccessMessage'))
            await delay(1500)
            setMe(null)
            navigate('/login')
        } else if (action === 'go-profile') {
            navigate('/me')
        } else if (action === 'download-app') {
            downloadRef.current.click()
        }
    }

    function onMenuItemClick(item) {
        if (item.key === 'main') {
            navigate('/')
        } else if (item.key === 'products') {
            navigate('/products')
        } else if (item.key === 'companies') {
            navigate('/companies')
        } else if (item.key === 'used-products') {
            navigate('/used-products')
        } else if (item.key === 'employees') {
            navigate('/employees')
        } else if (item.key === 'teams') {
            navigate('/teams')
        } else if (item.key === 'jobs') {
            navigate('/jobs')
        } else if (item.key === 'jobs-awaiting-action') {
            navigate('/jobs-awaiting-action')
        } else if (item.key === 'job-categories') {
            navigate('/job-categories')
        } else if (item.key === 'holes') {
            navigate('/holes')
        } else if (item.key === 'hole-categories') {
            navigate('/hole-categories')
        } else if (item.key === 'collects') {
            navigate('/collects')
        } else if (item.key === 'collect-categories') {
            navigate('/collect-categories')
        }
    }

    return (
        <>
            <a ref={downloadRef} href={`${process.env.REACT_APP_API_URL}/apk`} className="d-none">-</a>
            <Layout style={{minHeight: '100vh'}}>
                {contextHolder}
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    <div className="header-logo vertical-center">
                        <img src="/logo.png" alt="metehaz.com" style={{'width': '100%'}} />
                    </div>
                    <Menu theme="dark" defaultSelectedKeys={['main']} onClick={onMenuItemClick} mode="inline" items={MENU_ITEMS(t, awaitingActionJobsCount)} />
                </Sider>
                <Layout style={{ background: 'rgba(0,0,0,0.07)' }}>
                    {<LayoutHeader userActions={userActions} />}
                    <Content className="layout-content" style={{margin: 24}}>
                        {outlet || <MainContent />}
                    </Content>
                    <Footer style={{textAlign: 'center'}}>
                        Metehaz İş Takip ©2023
                    </Footer>
                </Layout>
            </Layout>
        </>
    )
}

function LayoutHeader({userActions}) {
    const { i18n, t } = useTranslation()

    return (
        <Header style={{padding: 0, textAlign: 'right'}}>
            <Space>
                <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => userActions('download-app')}>{t('downloadApp')}</Button>
                <Select
                    onChange={(lang) => i18n.changeLanguage(lang)}
                    defaultValue={i18n.language}
                    value={i18n.language} options={i18n.options.languages.map((lang) => ({ value: lang, label: lang }))}
                />
                <Popover
                    placement="bottom" trigger="click"
                    overlayInnerStyle={{padding:0}}
                    content={<Space direction="vertical" gap={0}>
                        <Button type="text" onClick={() => userActions('go-profile')}><UserOutlined /> {t('myProfile')}</Button>
                        <Button type="text" onClick={() => userActions('logout')}><LogoutOutlined /> {t('logout')}</Button>
                    </Space>}
                >
                    <Avatar icon={<UserOutlined />} style={{marginRight: 24, background: 'rgba(255,255,255,.2)', cursor: 'pointer'}} />
                </Popover>
            </Space>
        </Header>
    )
}

export default MainPage
