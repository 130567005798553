import { axiosInstance } from './../constants/constants'

async function create(data) {
    try {
        const response = await axiosInstance.post(`/api/v1/manager/product`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function read(productId) {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/product/${productId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function update(productId, data) {
    try {
        const response = await axiosInstance.put(`/api/v1/manager/product/${productId}`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function remove(productId) {
    try {
        const response = await axiosInstance.delete(`/api/v1/manager/product/${productId}`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function list() {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/product/list`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

export {
    create,
    read,
    update,
    remove,
    list,
}
