import { Tag } from 'antd'
import * as imageService from './../../../services/image'

export const COMPANIES_TABLE_COLUMNS = (t) => {
    return [
        {title: '#', dataIndex: 'company_id', key: 'company_id', render: companyId => `#${companyId}`},
        {title: t('name'), dataIndex: 'name', key: 'name'},
        {title: t('logo'), dataIndex: 'logo_md5', key: 'logo_md5', render: logoMd5 => logoMd5 ? (
            <div className="vertical-center" key={logoMd5}>
                <img
                    src={imageService.readUrl(logoMd5 + '-thumb')}
                    alt={logoMd5}
                    style={{'width': 36, 'height': 36, 'objectFit': 'cover', 'borderRadius': 5, 'border': '1px solid black'}}
                />
            </div>
        ) : '-'},
        {title: t('teams'), dataIndex: 'teams', key: 'teams', render: (teams) => teams?.map(team => <Tag key={team.team_id}>{team.name}</Tag>) || '-'},
        {title: t('employees'), dataIndex: 'employees', key: 'employees', render: (employees) => employees?.map(employee => (
            <Tag key={employee.employee_id}>{employee.name_surname}</Tag>
        )) ?? '-'},
    ]
}