export const JOB_STEP_TYPE_OPTIONS = [
    {value: 'Signaling', label: 'Sinyalizasyon'},
    {value: 'TechnicalSupport', label: 'Teknik Destek'},
    {value: 'ExcavationOpen', label: 'Çukur Açma'},
    {value: 'ExcavationClose', label: 'Çukur Kapama'},
    {value: 'Expertise', label: 'Eksper'},
    {value: 'Collect', label: 'Toplama'},
    {value: 'HardwoodFlooring', label: 'Parke Döşeme'}
]

export const JOB_STEP_TYPES = {
    'Signaling': 'Sinyalizasyon',
    'TechnicalSupport': 'Teknik Destek',
    'ExcavationOpen': 'Çukur Açma',
    'ExcavationClose': 'Çukur Kapama',
    'Expertise': 'Eksper',
    'Collect': 'Toplama',
    'HardwoodFlooring': 'Parke Döşeme'
}