import { useEffect, useState } from "react"
import { Space, Collapse, Spin, Select, Form, Row, Col, DatePicker, Input, Card, Button, Table, message } from 'antd'
import { ReloadOutlined, FilterOutlined, PlusCircleOutlined } from '@ant-design/icons'
import * as jobService from './../../../services/job'
import { JOBS_TABLE_COLUMNS } from './constants'
import { useNavigate, useOutlet, Outlet } from 'react-router-dom'
import { processDataForServer } from "../../../utils/helpers"
import jobIcon from './../../../assets/images/job.png'
import { useTranslation } from "react-i18next"

function JobsPage({onSelect}) {
    const navigate = useNavigate()
    const outlet = useOutlet()
    const [messageApi, contextHolder] = message.useMessage()
    const [jobs, setJobs] = useState()
    const [pagination, setPagination] = useState({})
    const [searchData, setSearchData] = useState({'is_open': true})
    const { t } = useTranslation()

    async function loadJobs(page) {
        setJobs(null)
        const jobListResponse = await jobService.list({
            ...searchData,
            'limit': 50,
            'offset': (page * 50) - 50
        })
        if (!jobListResponse.success) {
            messageApi.error(jobListResponse.message)
            return
        }

        setJobs(jobListResponse.data.jobs.map(job => ({...job, 'key': job.job_id})))
        setPagination({'total': jobListResponse.data.total, 'pageSize': 50, 'current': page})
    }

    useEffect(() => {
        loadJobs(1)
        // eslint-disable-next-line
    }, [])

    function onNewJobButtonClick() {
        navigate('/jobs/new')
    }

    function onJobCreateEvents(event) {
        if (event === 'create') {
            loadJobs(pagination.current)
        }
    }

    function onJobUpdateEvents(event, eventData) {
        if (event === 'update') {
            loadJobs(pagination.current)
        } else if (event === 'remove') {
            setJobs(lastState => lastState.filter(job => job.job_id !== eventData.job_id))
        }
    }

    function onRowClick() {
        if (onSelect) {
            onSelect(this)
            return
        }

        navigate(`/jobs/detail/${this.job_id}`)
    }

    useEffect(() => {
        if (!searchData) {
            return
        }

        loadJobs(1)
        // eslint-disable-next-line
    }, [searchData])

    function onSearch(values) {
        setSearchData(values)
    }

    return (
        <>
            {contextHolder}
            {!outlet && <Card
                title={<div className="vertical-center"><img src={jobIcon} alt="" style={{ 'width': 22, 'height': 22 }} /> &nbsp;&nbsp;{t('jobs')}</div>} bodyStyle={{padding: 0}}
                extra={
                    !onSelect && <Space>
                        <Button icon={<PlusCircleOutlined />} onClick={onNewJobButtonClick} loading={!jobs}>{t('addNew')}</Button>
                        <Button icon={<ReloadOutlined />} onClick={() => loadJobs(pagination.current)} loading={!jobs} />
                    </Space>
                }
                className="w-100"
            >
                <ListFilter onSearch={onSearch} />
                <Table size="small" dataSource={jobs} columns={JOBS_TABLE_COLUMNS(t)} loading={!jobs} onRow={record => ({onClick: onRowClick.bind(record)})}/>
            </Card>}
            {outlet && <Outlet context={{'jobCreateEvent': onJobCreateEvents, 'jobUpdateEvent': onJobUpdateEvents}} />}
        </>
    )
}

function ListFilter({ onSearch }) {
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [categories, setCategories] = useState(null)
    const [isActive, setIsActive] = useState(true)
    const { t } = useTranslation()

    async function loadCategories() {
        setCategories(null)
        const categoryListResponse = await jobService.categoryList()
        if (!categoryListResponse.success) {
            messageApi.error(categoryListResponse.message)
            return
        }

        setCategories(categoryListResponse.data.categories.map(category => ({...category, 'key': category.job_category_id})))
    }

    useEffect(() => {
        loadCategories()
        // eslint-disable-next-line
    }, [])

    async function onFinish(values) {
        values = processDataForServer(values)
        if (values.date) {
            values.date = values.date.format('YYYY-MM-DD')
        }
        onSearch(values)
    }

    function checkFormActive(values) {
        setIsActive(Object.values(values).filter(value => typeof value !== 'undefined')
            .filter(value => value !== null)
            .filter(value => typeof value != 'string' || (typeof value === 'string' && value.length > 0))
            .length > 0
        )
    }

    return (
        <>
            {contextHolder}
            <Form name="filter-list" onValuesChange={checkFormActive} form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                <Collapse
                    size="small"
                    bordered={false}
                    items={[{
                        'key': 'filter',
                        'label': <span style={{'color': isActive ? 'red': 'black'}}><FilterOutlined /> {t('filter')}</span>,
                        'children': categories ? (
                            <Row gutter={[24, 8]}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item label={t('category')} name="category_id" className="m-0">
                                        <Select options={categories?.map(category => ({'value': category.job_category_id, 'label': category.name}))} allowClear className="m-0" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item label={t('date')} name="date" className="m-0">
                                        <DatePicker className="w-100" allowClear />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item initialValue={true} label={t('jobStatus')} name="is_open" className="m-0">
                                        <Select options={[{'value': true, 'label': t('open')}, {'value': false, 'label': t('closed')}]} allowClear className="m-0" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{'float': 'left', 'textAlign': 'right'}}>
                                    <Button type="primary" htmlType="submit">{t('search')}</Button>
                                </Col>
                            </Row>
                        ) : <Spin />
                    }]}
                />
                <Form.Item name="query" className="m-0">
                    <Input.Search styles={{input: {'borderRadius': 0}}} placeholder={t('searchPlaceholder')} maxLength={100} onSearch={() => onFinish(form.getFieldsValue())} enterButton borderless="true" />
                </Form.Item>
            </Form>
        </>
    )
}

export default JobsPage
