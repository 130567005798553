import { createBrowserRouter } from 'react-router-dom'
import AuthLoginPage from './../pages/auth/authLoginPage'

import MainPage from './../pages/main/mainPage'

import MePage from './../pages/me/mePage'

import ProductsPage from './../pages/products/products/productsPage'
import CreateProductPage from './../pages/products/productCreate/productCreatePage'
import UpdateProductPage from './../pages/products/productUpdate/productUpdatePage'

import CompaniesPage from './../pages/companies/companies/companiesPage'
import CreateCompanyPage from './../pages/companies/companyCreate/companyCreatePage'
import UpdateCompanyPage from './../pages/companies/companyUpdate/companyUpdatePage'

import EmployeesPage from './../pages/employees/employees/employeesPage'
import CreateEmployeePage from './../pages/employees/employeeCreate/employeeCreatePage'
import UpdateEmployeePage from './../pages/employees/employeeUpdate/employeeUpdatePage'

import TeamsPage from './../pages/teams/teams/teamsPage'
import CreateTeamPage from './../pages/teams/teamCreate/teamCreatePage'
import UpdateTeamPage from './../pages/teams/teamUpdate/teamUpdatePage'

import JobsPage from './../pages/jobs/jobs/jobsPage'
import CreateJobPage from './../pages/jobs/jobCreate/jobCreatePage'
import UpdateJobPage from './../pages/jobs/jobUpdate/jobUpdatePage'
import DetailJobPage from './../pages/jobs/jobDetail/jobDetailPage'

import {default as JobCategoriesPage} from './../pages/jobs/categories/categories/categoriesPage'
import {default as CreateJobCategoryPage} from './../pages/jobs/categories/categoryCreate/categoryCreatePage'
import {default as UpdateJobCategoryPage} from './../pages/jobs/categories/categoryUpdate/categoryUpdatePage'
import JobStepCreatePage from '../pages/jobs/jobStepCreate/jobStepCreatePage'
import JobStepUpdatePage from '../pages/jobs/jobStepUpdate/jobStepUpdatePage'
import JobsAwaitingActionPage from '../pages/jobs/jobsAwaitingAction/jobsAwaitingActionPage'

export default createBrowserRouter([
    {path: "/login", Component: AuthLoginPage},
    {path: "/", Component: MainPage, children: [
        {path: "me", Component: MePage},
        {path: "products", Component: ProductsPage, children: [
            {path: "new", Component: CreateProductPage},
            {path: "update/:productId", Component: UpdateProductPage},
        ]},
        {path: "companies", Component: CompaniesPage, children: [
            {path: "new", Component: CreateCompanyPage},
            {path: "update/:companyId", Component: UpdateCompanyPage},
        ]},
        {path: "employees", Component: EmployeesPage, children: [
            {path: "new", Component: CreateEmployeePage},
            {path: "update/:employeeId", Component: UpdateEmployeePage},
        ]},
        {path: "teams", Component: TeamsPage, children: [
            {path: "new", Component: CreateTeamPage},
            {path: "update/:teamId", Component: UpdateTeamPage},
        ]},
        {path: "jobs", Component: JobsPage, children: [
            {path: "new", Component: CreateJobPage},
            {path: "update/:jobId", Component: UpdateJobPage},
            {path: "detail/:jobId", Component: DetailJobPage, children: [
                {path: "step/new", Component: JobStepCreatePage},
                {path: "step/:jobStepId/update", Component: JobStepUpdatePage},
            ]},
        ]},
        {path: "jobs-awaiting-action", Component: JobsAwaitingActionPage, children: [
            {path: "update/:jobId", Component: UpdateJobPage},
            {path: "detail/:jobId", Component: DetailJobPage, children: [
                {path: "step/new", Component: JobStepCreatePage},
                {path: "step/:jobStepId/update", Component: JobStepUpdatePage},
            ]},
        ]},
        {path: "job-categories", Component: JobCategoriesPage, children: [
            {path: "new", Component: CreateJobCategoryPage},
            {path: "update/:jobCategoryId", Component: UpdateJobCategoryPage},
        ]}
    ]}
])
