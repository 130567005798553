import { useEffect, useState } from "react"
import { Col, Row, Card, Button, Table, message } from 'antd'
import { PlusCircleOutlined, UserOutlined } from '@ant-design/icons'
import * as employeeService from './../../../services/employee'
import { EMPLOYEES_TABLE_COLUMNS } from './constants'
import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function EmployeesPage() {
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const [employees, setEmployees] = useState()
    const { t } = useTranslation()

    async function loadEmployees() {
        setEmployees(null)
        const employeeListResponse = await employeeService.list()
        if (!employeeListResponse.success) {
            messageApi.error(employeeListResponse.message)
            return
        }

        setEmployees(employeeListResponse.data.employees.map(employee => ({...employee, 'key': employee.employee_id})))
    }

    useEffect(() => {
        loadEmployees()
        // eslint-disable-next-line
    }, [])

    function onNewEmployeeButtonClick() {
        navigate('/employees/new')
    }

    function onEmployeeCreateEvents(event) {
        if (event === 'create') {
            loadEmployees()
        }
    }

    function onEmployeeUpdateEvents(event, eventData) {
        if (event === 'update') {
            loadEmployees()
        } else if (event === 'remove') {
            setEmployees(lastState => lastState.filter(employee => employee.employee_id !== eventData.employee_id))
        }
    }

    function onRowClick() {
        navigate(`/employees/update/${this.employee_id}`)
    }

    return (
        <>
            {contextHolder}
            <Row justify="start" align="top" className="w-100 h-100" gutter={24}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Card
                        title={<><UserOutlined /> {t('employees')}</>} bodyStyle={{padding: 0}}
                        extra={<Button icon={<PlusCircleOutlined />} onClick={onNewEmployeeButtonClick} loading={!employees}>{t('addNew')}</Button>}
                    >
                        <Table size="small" dataSource={employees} columns={EMPLOYEES_TABLE_COLUMNS(t)} loading={!employees} onRow={record => ({onClick: onRowClick.bind(record)})}/>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Outlet context={{'employeeCreateEvent': onEmployeeCreateEvents, 'employeeUpdateEvent': onEmployeeUpdateEvents}} />
                </Col>
            </Row>
        </>
    )
}

export default EmployeesPage
