import { useState } from "react"
import { DatePicker, Card, Form, Input, Button, Select, message } from 'antd'
import { formRules } from './../../../constants/constants'
import { UserOutlined, CloseOutlined } from '@ant-design/icons'
import * as employeeService from './../../../services/employee'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function EmployeeCreatePage() {
    const navigate = useNavigate()
    const {employeeCreateEvent} = useOutletContext()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const { t } = useTranslation()

    async function onFinish(values) {
        setIsSubmit(true)

        if (values.start_date) {
            values.start_date = values.start_date.unix()
        }

        if (values.end_date) {
            values.end_date = values.end_date.unix()
        }

        const employeeCreateResponse = await employeeService.create(values)
        if (!employeeCreateResponse.success) {
            messageApi.error(employeeCreateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('employeeCreatedSuccessMessage'))
        setIsSubmit(false)
        employeeCreateEvent('create')
        form.resetFields()
    }

    function onClose() {
        navigate('/employees', {replace: true})
    }

    return (
        <>
            {contextHolder}
            <Card title={<><UserOutlined /> {t('newEmployee')}</>} extra={<CloseOutlined onClick={onClose} />}>
                <Form name="employee-create" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                    <Form.Item label={t('nameSurname')} name="name_surname" rules={[formRules.required, formRules.min(3), formRules.max(100)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('startDate')} name="start_date">
                        <DatePicker className="w-100" format="YYYY-MM-DD" allowClear />
                    </Form.Item>
                    <Form.Item label={t('endDate')} name="end_date">
                        <DatePicker className="w-100" format="YYYY-MM-DD" allowClear />
                    </Form.Item>
                    <Form.Item label={t('debit')} name="custodies">
                        <Select mode="tags" maxTagTextLength={100} />
                    </Form.Item>
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                    </div>
                </Form>
            </Card>
        </>
    )
}

export default EmployeeCreatePage
