import { useEffect, useState, useRef } from "react"
import { Image, Modal, Space, Card, Form, Input, Button, message } from 'antd'
import { formRules } from './../../../constants/constants'
import { DeleteOutlined, CloseOutlined, UnorderedListOutlined } from '@ant-design/icons'
import * as productService from './../../../services/product'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { confirmModal } from "../../../utils/helpers"
import * as imageService from './../../../services/image'
import { useTranslation } from "react-i18next"

function ProductUpdatePage() {
    const navigate = useNavigate()
    const [modal, modalContextHolder] = Modal.useModal()
    const {productUpdateEvent} = useOutletContext()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const [product, setProduct] = useState()
    const [image, setImage] = useState(null)
    const { t } = useTranslation()
    const inputImageRef = useRef()

    let {productId} = useParams()
    productId = parseInt(productId)

    async function loadProduct() {
        const productReadResponse = await productService.read(productId)
        if (!productReadResponse.success) {
            messageApi.error(productReadResponse.message)
            return
        }

        if (!productReadResponse.data.product) {
            messageApi.error(t('notFound'))
            return
        }

        form.setFieldsValue(productReadResponse.data.product)
        setProduct(productReadResponse.data.product)
        setImage(productReadResponse.data.product.image_md5)
    }

    useEffect(() => {
        loadProduct()
        // eslint-disable-next-line
    }, [productId])

    async function onFinish(values) {
        setIsSubmit(true)
        values.image_md5 = image || null
        const productUpdateResponse = await productService.update(productId, values)
        if (!productUpdateResponse.success) {
            messageApi.error(productUpdateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('productUpdatedSuccessMessage'))
        setIsSubmit(false)
        productUpdateEvent('update')
    }

    async function removeProduct() {
        const confirmResult = await confirmModal(modal, {title: t('productWillDeleted')})
        if (!confirmResult) {
            return
        }

        setIsSubmit(true)
        const productRemoveResponse = await productService.remove(productId)
        if (!productRemoveResponse.success) {
            messageApi.error(productRemoveResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('productDeletedSuccessMessage'))
        setIsSubmit(false)
        productUpdateEvent('remove', {'product_id': productId})
        navigate('/products', {replace: true})
    }

    function onClose() {
        navigate('/products', {replace: true})
    }

    async function onImageSelect(event) {
        if (event.target.files.length) {
            const formData = new FormData()
            formData.append('image', event.target.files[0])
            const imageCreateResponse = await imageService.create(formData)
            if (!imageCreateResponse.success) {
                messageApi.error(imageCreateResponse.message)
                return
            }
            setImage(imageCreateResponse.data.md5)
        }
    }

    return (
        <>
            {contextHolder}
            {modalContextHolder}
            <Card title={<><UnorderedListOutlined /> {t('productUpdate')}</>} loading={!product} extra={<CloseOutlined onClick={onClose} />}>
                <Form name="product-update" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                    <Form.Item label={t('name')} name="name" rules={[formRules.required, formRules.min(3), formRules.max(100)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('stockCode')} name="stock_code" rules={[formRules.min(1), formRules.max(100)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('unit')} name="unit" rules={[formRules.required, formRules.min(1), formRules.max(20)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('image')}>
                        {image && <div className="w-100" style={{'position': 'relative'}}>
                            <Image className="w-100" height={250} src={imageService.readUrl(image)} style={{'objectFit': 'cover', 'border': '1px solid rgba(0,0,0,0.5)'}} />
                            <DeleteOutlined onClick={() => setImage()} size="large" style={{'position': 'absolute', 'right': 10, 'top': 10}} />
                        </div>}
                        {!image && <Button onClick={() => inputImageRef.current.click()}>{t('choose')}</Button>}
                    </Form.Item>
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Space>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" danger onClick={removeProduct}>{t('remove')}</Button>
                            <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
            <input ref={inputImageRef} accept="image/*" type='file' id="image-input" className="d-none" onChange={event => onImageSelect(event)} />
        </>
    )
}

export default ProductUpdatePage
