import { useContext, useState } from "react"
import MeContext from './../../context/meContext'
import { Col, Row, Card, Form, Input, Button, message } from 'antd'
import { formRules } from './../../constants/constants'
import { UserOutlined } from '@ant-design/icons'
import * as meService from './../../services/me'
import { useTranslation } from "react-i18next"

function MePage() {
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const {me, setMe} = useContext(MeContext)
    const { t } = useTranslation()

    async function onFinish(values) {
        setIsSubmit(true)
        const meUpdateResponse = await meService.update(values)
        if (!meUpdateResponse.success) {
            messageApi.error(meUpdateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('myProfileInformationsUpdatedSuccessMessage'))
        setMe({...me, ...values})
        setIsSubmit(false)
    }

    return (
        <>
            {contextHolder}
            <Row justify="start" align="top" className="w-100 h-100">
                <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                    <Card title={<><UserOutlined /> {t('myProfileInformations')}</>}>
                        <Form initialValues={me} name="login" labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                            <Form.Item label={t('nameSurname')} name="name_surname" rules={[formRules.required, formRules.min(3), formRules.max(100)]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t('eMail')} name="e_mail" rules={[formRules.required, formRules.email]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t('password')} name="password" rules={[formRules.min(6), formRules.max(20)]}>
                                <Input.Password />
                            </Form.Item>
                            <div style={{ marginTop: 24, textAlign: 'right' }}>
                                <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default MePage
