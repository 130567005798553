import React, { useState, useContext } from 'react'
import { Col, Row, Card, Form, Input, Button, Divider, message } from 'antd'
import { formRules } from './../../constants/constants'
import { UserOutlined } from '@ant-design/icons'
import * as authService from './../../services/auth'
import MeContext from '../../context/meContext'
import { delay } from './../../utils/helpers'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function AuthLoginPage() {
    const navigate = useNavigate()
    const { setMe } = useContext(MeContext)
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const { t } = useTranslation()

    async function onFinish(values) {
        setIsSubmit(true)
        const authLoginResponse = await authService.login(values.e_mail, values.password)
        if (!authLoginResponse.success) {
            messageApi.error(authLoginResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('loginSuccessMessage'))
        localStorage.setItem('xToken', authLoginResponse.data.token)

        await delay(1500)

        setMe(authLoginResponse.data.me)
        navigate('/')
    }

    return (
        <>
            {contextHolder}
            <Row justify="space-around" align="middle" className="w-100 h-100">
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Card title={<><UserOutlined /> Login</>}>
                        <Form name="login" layout="vertical" onFinish={onFinish} autoComplete="off">
                            <Form.Item label={t('eMail')} name="e_mail" rules={[formRules.required, formRules.email]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t('password')} name="password" rules={[formRules.required, formRules.min(6), formRules.max(20)]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item style={{ marginTop: 24 }}>
                                <Button loading={isSubmit} disabled={isSubmit} className="w-100" type="primary" htmlType="submit">{t('login')}</Button>
                            </Form.Item>
                        </Form>
                        <Divider>Metehaz İş Takip</Divider>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AuthLoginPage
