import { useEffect, useState } from "react"
import { Col, Row, Card, Button, Table, message } from 'antd'
import { PlusCircleOutlined, TeamOutlined } from '@ant-design/icons'
import * as teamService from './../../../services/team'
import { TEAMS_TABLE_COLUMNS } from './constants'
import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function TeamsPage() {
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const [teams, setTeams] = useState()
    const { t } = useTranslation()

    async function loadTeams() {
        setTeams(null)
        const teamListResponse = await teamService.list()
        if (!teamListResponse.success) {
            messageApi.error(teamListResponse.message)
            return
        }

        setTeams(teamListResponse.data.teams.map(team => ({...team, 'key': team.team_id})))
    }

    useEffect(() => {
        loadTeams()
        // eslint-disable-next-line
    }, [])

    function onNewTeamButtonClick() {
        navigate('/teams/new')
    }

    function onTeamCreateEvents(event) {
        if (event === 'create') {
            loadTeams()
        }
    }

    function onTeamUpdateEvents(event, eventData) {
        if (event === 'update') {
            loadTeams()
        } else if (event === 'remove') {
            setTeams(lastState => lastState.filter(team => team.team_id !== eventData.team_id))
        }
    }

    function onRowClick() {
        navigate(`/teams/update/${this.team_id}`)
    }

    return (
        <>
            {contextHolder}
            <Row justify="start" align="top" className="w-100 h-100" gutter={24}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Card
                        title={<><TeamOutlined /> {t('teams')}</>} bodyStyle={{padding: 0}}
                        extra={<Button icon={<PlusCircleOutlined />} onClick={onNewTeamButtonClick} loading={!teams}>{t('addNew')}</Button>}
                    >
                        <Table size="small" dataSource={teams} columns={TEAMS_TABLE_COLUMNS(t)} loading={!teams} onRow={record => ({onClick: onRowClick.bind(record)})}/>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Outlet context={{'teamCreateEvent': onTeamCreateEvents, 'teamUpdateEvent': onTeamUpdateEvents}} />
                </Col>
            </Row>
        </>
    )
}

export default TeamsPage
