import { useState } from "react"
import { Card, Form, Input, Button, ColorPicker, message } from 'antd'
import { formRules } from './../../../../constants/constants'
import { TagOutlined, CloseOutlined } from '@ant-design/icons'
import * as jobService from './../../../../services/job'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function CategoryCreatePage() {
    const navigate = useNavigate()
    const {categoryCreateEvent} = useOutletContext()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmit, setIsSubmit] = useState(false)
    const { t } = useTranslation()

    async function onFinish(values) {
        setIsSubmit(true)
        values.color = `#${values.color.toHex()}`
        const categoryCreateResponse = await jobService.categoryCreate(values)
        if (!categoryCreateResponse.success) {
            messageApi.error(categoryCreateResponse.message)
            setIsSubmit(false)
            return
        }

        messageApi.success(t('categoryCreatedSuccessMessage'))
        setIsSubmit(false)
        categoryCreateEvent('create')
        form.resetFields()
    }

    function onClose() {
        navigate('/job-categories', {replace: true})
    }

    return (
        <>
            {contextHolder}
            <Card title={<><TagOutlined /> {t('newCategory')}</>} extra={<CloseOutlined onClick={onClose} />}>
                <Form name="category-create" form={form} labelCol={{span: 6}} wrapperCol={{span: 18}} labelAlign="left" onFinish={onFinish} autoComplete="off">
                    <Form.Item label={t('name')} name="name" rules={[formRules.required, formRules.min(1), formRules.max(100)]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('color')} name="color" rules={[formRules.required]}>
                        <ColorPicker disabledAlpha format="hex" />
                    </Form.Item>
                    <div style={{ marginTop: 24, textAlign: 'right' }}>
                        <Button loading={isSubmit} disabled={isSubmit} type="primary" htmlType="submit">{t('save')}</Button>
                    </div>
                </Form>
            </Card>
        </>
    )
}

export default CategoryCreatePage
