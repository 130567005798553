import { axiosInstance } from './../constants/constants'

async function read() {
    try {
        const response = await axiosInstance.get(`/api/v1/manager/me`, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

async function update(data) {
    try {
        const response = await axiosInstance.put(`/api/v1/manager/me`, data, {
            'headers': {'x-token': localStorage.getItem('xToken')}
        })
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

export {
    read,
    update
}
