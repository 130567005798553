import ReactDOM from 'react-dom/client'
import './index.css'
import App from './app/app'
import { MeProvider } from './context/meContext'
import "./i18n"

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <MeProvider>
        <App />
    </MeProvider>
)
