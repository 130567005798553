import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

export const JOBS_TABLE_COLUMNS = (t) => {
    return [
        {title: '#', dataIndex: 'job_id', key: 'job_id', render: jobId => `#${jobId}`},
        {title: t('name'), dataIndex: 'name', key: 'name', render: name => name || '-'},
        {title: t('category'), dataIndex: ['category', 'name'], key: 'category', render: (category, record) => <span style={{'color': record?.category?.color}}>{category}</span> || '-'},
        {title: t('address'), dataIndex: 'address', key: 'address', render: address => address ? address.substring(0, 50) : '-'},
        {title: t('jobDate'), dataIndex: 'start_date', key: 'start_date', render: (_, record) => moment(record.start_date).format('DD/MM HH:mm')},
        {title: t('status'), dataIndex: 'is_open', key: 'is_open', align: 'center', render: isOpen => isOpen ? <CloseCircleOutlined style={{'color': 'red'}} /> : <CheckCircleOutlined style={{'color': 'blue'}} />}
    ]
}