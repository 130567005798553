import { useEffect, useState } from "react"
import { Col, Row, Card, Button, Table, message } from 'antd'
import { PlusCircleOutlined, TagsOutlined } from '@ant-design/icons'
import * as jobService from './../../../../services/job'
import { CATEGORIES_TABLE_COLUMNS } from './constants'
import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function CategoriesPage() {
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const [categories, setCategories] = useState()
    const { t } = useTranslation()

    async function loadCategories() {
        setCategories(null)
        const categoryListResponse = await jobService.categoryList()
        if (!categoryListResponse.success) {
            messageApi.error(categoryListResponse.message)
            return
        }

        setCategories(categoryListResponse.data.categories.map(category => ({...category, 'key': category.job_category_id})))
    }

    useEffect(() => {
        loadCategories()
        // eslint-disable-next-line
    }, [])

    function onNewCategoryButtonClick() {
        navigate('/job-categories/new')
    }

    function onCategoryCreateEvents(event) {
        if (event === 'create') {
            loadCategories()
        }
    }

    function onCategoryUpdateEvents(event, eventData) {
        if (event === 'update') {
            loadCategories()
        } else if (event === 'remove') {
            setCategories(lastState => lastState.filter(category => category.job_category_id !== eventData.job_category_id))
        }
    }

    function onRowClick() {
        navigate(`/job-categories/update/${this.job_category_id}`)
    }

    return (
        <>
            {contextHolder}
            <Row justify="start" align="top" className="w-100 h-100" gutter={24}>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Card
                        title={<><TagsOutlined /> {t('jobCategories')}</>} bodyStyle={{padding: 0}}
                        extra={<Button icon={<PlusCircleOutlined />} onClick={onNewCategoryButtonClick} loading={!categories}>{t('addNew')}</Button>}
                    >
                        <Table size="small" dataSource={categories} columns={CATEGORIES_TABLE_COLUMNS(t)} loading={!categories} onRow={record => ({onClick: onRowClick.bind(record)})}/>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Outlet context={{'categoryCreateEvent': onCategoryCreateEvents, 'categoryUpdateEvent': onCategoryUpdateEvents}} />
                </Col>
            </Row>
        </>
    )
}

export default CategoriesPage
