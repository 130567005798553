import { useEffect, useState } from "react"
import { Card, Table, Button, message } from 'antd'
import * as jobService from '../../../services/job'
import { JOBS_TABLE_COLUMNS } from './constants'
import { useNavigate, useOutlet, Outlet } from 'react-router-dom'
import jobIcon from './../../../assets/images/job.png'
import { ReloadOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next"

function JobsAwaitingActionPage({onSelect}) {
    const navigate = useNavigate()
    const outlet = useOutlet()
    const [messageApi, contextHolder] = message.useMessage()
    const [jobs, setJobs] = useState()
    const { t } = useTranslation()

    async function loadJobs(page) {
        setJobs(null)
        const jobListResponse = await jobService.listAwaitingAction()
        if (!jobListResponse.success) {
            messageApi.error(jobListResponse.message)
            return
        }

        setJobs(jobListResponse.data.jobs.map(job => ({...job, 'key': job.job_id})))
    }

    useEffect(() => {
        loadJobs(1)
        // eslint-disable-next-line
    }, [])

    function onRowClick() {
        if (onSelect) {
            onSelect(this)
            return
        }

        navigate(`/jobs-awaiting-action/detail/${this.job_id}`)
    }

    return (
        <>
            {contextHolder}
            {!outlet && <Card
                title={<div className="vertical-center">
                    <img src={jobIcon} alt="" style={{ 'width': 22, 'height': 22 }} /> &nbsp;&nbsp;{t('awaitingAction')}
                </div>}
                bodyStyle={{padding: 0}}
                className="w-100"
                extra={<Button icon={<ReloadOutlined />} onClick={loadJobs} loading={!jobs} />}
            >
                <Table
                    size="small"
                    pagination={false}
                    dataSource={jobs}
                    columns={JOBS_TABLE_COLUMNS(t)}
                    loading={!jobs}
                    onRow={record => ({onClick: onRowClick.bind(record)})}
                />
            </Card>}
            {outlet && <Outlet context={{'jobUpdateEvent': loadJobs}} />}
        </>
    )
}

export default JobsAwaitingActionPage
