export const TEAM_SERVICES_OPTIONS = [
    {value: 'Signaling', label: 'Sinyalizasyon'},
    {value: 'TechnicalSupport', label: 'Teknik Destek'},
    {value: 'Excavation', label: 'Çukur'},
    {value: 'Warehouse', label: 'Depo'},
    {value: 'Expertise', label: 'Eksper'},
    {value: 'HardwoodFlooring', label: 'Parke Döşeme'},
    {value: 'Collect', label: 'Toplama'},
]

export const TEAM_SERVICES = {
    'Signaling': 'Sinyalizasyon',
    'TechnicalSupport': 'Teknik Destek',
    'Excavation': 'Çukur',
    'Warehouse': 'Depo',
    'Expertise': 'Eksper',
    'HardwoodFlooring': 'Parke Döşeme',
    'Collect': 'Toplama'
}