import { axiosInstance } from './../constants/constants'

async function getCoordinateAddress(coordinate) {
    try {
        const response = await axiosInstance.get(`https://nominatim.openstreetmap.org/reverse?lat=${coordinate[0]}&lon=${coordinate[1]}&format=json&accept-language=en`)
        return response.data
    } catch (err) {
        return {'success': false, 'message': 'Bir hata ile karşılaşıldı!'}
    }
}

export {
    getCoordinateAddress
}
