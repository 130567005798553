import { useEffect, useState } from "react"
import { Col, Row, Card, Button, Table, message } from 'antd'
import { PlusCircleOutlined, UnorderedListOutlined } from '@ant-design/icons'
import * as productService from './../../../services/product'
import { PRODUCTS_TABLE_COLUMNS } from './constants'
import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function ProductsPage() {
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const [products, setProducts] = useState()
    const { t } = useTranslation()

    async function loadProducts() {
        setProducts(null)
        const productListResponse = await productService.list()
        if (!productListResponse.success) {
            messageApi.error(productListResponse.message)
            return
        }

        setProducts(productListResponse.data.products.map(product => ({...product, 'key': product.product_id})))
    }

    useEffect(() => {
        loadProducts()
        // eslint-disable-next-line
    }, [])

    function onNewProductButtonClick() {
        navigate('/products/new')
    }

    function onProductCreateEvents(event) {
        if (event === 'create') {
            loadProducts()
        }
    }

    function onProductUpdateEvents(event, eventData) {
        if (event === 'update') {
            loadProducts()
        } else if (event === 'remove') {
            setProducts(lastState => lastState.filter(product => product.product_id !== eventData.product_id))
        }
    }

    function onRowClick() {
        navigate(`/products/update/${this.product_id}`)
    }

    return (
        <>
            {contextHolder}
            <Row justify="start" align="top" className="w-100 h-100" gutter={24}>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Card
                        title={<><UnorderedListOutlined /> {t('products')}</>} bodyStyle={{padding: 0}}
                        extra={<Button icon={<PlusCircleOutlined />} onClick={onNewProductButtonClick} loading={!products}>{t('addNew')}</Button>}
                    >
                        <Table size="small" dataSource={products} columns={PRODUCTS_TABLE_COLUMNS(t)} loading={!products} onRow={record => ({onClick: onRowClick.bind(record)})}/>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Outlet context={{'productCreateEvent': onProductCreateEvents, 'productUpdateEvent': onProductUpdateEvents}} />
                </Col>
            </Row>
        </>
    )
}

export default ProductsPage
